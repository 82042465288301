import React, { useEffect, useState } from 'react';
import CourseBanner from './CourseBanner';
import CourseContent from './CourseContent';
import Reviews from './Reviews';
import CourseSlider from './CourseSlider';
import { useDispatch, useSelector } from 'react-redux';
import { getCourse } from '../../../redux/course/courseActions';
import { useParams } from 'react-router-dom';
import { adminApiHelper } from '../../../redux/adminApiHelper';
import { toast } from 'react-toastify';
import Loader from '../../../config/Loader';
function CourseDetails() {

	const dispatch = useDispatch();
	const { id } = useParams();
	const coursesData = useSelector((state) => state.course.course);
	const [isLoading, setIsLoading] = useState(false);
	const [discount, setDiscount] = useState(null);
	const courseContentData = {
		id: coursesData?._id,
		discountId: coursesData?.discountId,
		title: coursesData?.title,
		description: coursesData?.description,
		shortDescription: coursesData?.shortDescription,
		type: coursesData?.type,
		price: coursesData?.price,
		currency: coursesData?.currency,
		category: coursesData?.category,
		files: coursesData?.files,
		reviews: coursesData?.reviews,
		thumbnailPic: coursesData?.thumbnailpic,
		discount: coursesData?.discount,
		introductoryVideo: coursesData?.introductaryVideo,
	};

	const reviewsData = {
		courseId: id,
		reviews: coursesData?.reviews,
		introductoryVideo: coursesData?.introductaryVideo,
	}

	const sliderData = {
		courseId: id,
		category: coursesData?.category
	}

	useEffect(() => {
		setIsLoading(true);
		dispatch(getCourse(id)).finally(() => setIsLoading(false));
	}, [dispatch, id]);

	useEffect(() => {
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	}, []);

	const fetchDiscount = async (id) => {
		setIsLoading(true);
		try {
			const res = await adminApiHelper("get", `/discount/${id}`, "");
			if (res?.data) {
				const { data } = res;
				setDiscount(data?.data?.value)
			}
		} catch (error) {
			console.error(error);
			// toast.error(error.res.message);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (coursesData?.discountId) {
			fetchDiscount(coursesData.discountId)
		}
	}, [coursesData?.discountId]);

	return (
		<>
			{isLoading ?
				<Loader />
				:
				<>
					<CourseBanner bannerPic={coursesData?.bannerpic} />
					<CourseContent courseContentData={courseContentData} />
					<Reviews reviewsData={reviewsData} />
					<CourseSlider sliderData={sliderData} />
				</>
			}
		</>
	);
}

export default CourseDetails;
