import axios from "axios";
import { toast } from 'react-toastify';

let baseUrl = process.env.REACT_APP_BASE_ADMIN_API

async function adminApiHelper(apiType, path, data, params) {
   if (baseUrl === undefined || !baseUrl) {
      baseUrl = ""
   }

   if (apiType === "post" || apiType === "put" || apiType === "get" || apiType === "delete") {
      try {
         let response = await axios({
            method: apiType,
            url: `${baseUrl + path}`,
            data
         })
         return response
      } catch (error) {
         toast.error(error.response?.data?.message)
      }
   }
}

export { adminApiHelper };
