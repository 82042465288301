import React from "react";
import { ReactComponent as Star } from "../../../assets/images/star-icon.svg";
import { ReactComponent as StarIcon } from "../../../assets/images/star-icon.svg";

function TestimonialContentGrid({ testimonial }) {

    return (
        <div className="shahdat-wrapper">
            <div class="grid xm:grid-cols-2 sm:grid-cols-3 2xl:grid-cols-3 gap-4  " >
                {testimonial?.length
                    ? testimonial?.map((testimonial, index) => {
                        const rating = testimonial?.rating || 0;
                        const starIcons = Array.from({ length: 5 }, (_, i) => (
                            <div className="start_icon">
                                {i < rating ? <Star /> : <StarIcon className="blank_svg" />}
                            </div>
                        ));
                        return (
                            <div class="p-4 ">
                                <div key={index}>
                                    <div className="shahadat-slider p-6 text-center 2xl:pt-16 md:pt-16  ">
                                        <div className="star-icon flex items-center justify-center pb-6">
                                            {starIcons}
                                        </div>
                                        <h3 className="pb-24 font-light">{testimonial?.message}</h3>
                                        <h2 className="f-size-22">{testimonial?.name}</h2>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                    : ""}
            </div>
        </div>

    );
}

export default TestimonialContentGrid;
