import authReducer from "./auth/authReducer";
import courseReducer from "./course/courseReducer";
import settingsReducer from "./settings/settingsReducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk'

const middleware = [thunk]
const reducer = combineReducers({
  auth: authReducer,
  course: courseReducer,
  settings: settingsReducer
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store