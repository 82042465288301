import React from 'react'
import FaqBanner from './FaqBanner'
import Faq from './Faq'

function FaqPage() {
  return (
    <div>
      <FaqBanner />
      <div className='mt-4'>
      <Faq />
      </div>
    </div>
  )
}

export default FaqPage
