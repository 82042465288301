import React, { useEffect, useState } from 'react'
import AboutBanner from '../../main/AboutUs/AboutBanner';
import Hayyat from '../../common/Hayyat/Hayyat';
import Faqat from '../../common/Faqat/Faqat'
import Shahdat from '../../common/Shahadat/Shahdat';
import Benefit from './Benefit';
import './AboutUs.scss'

function AboutUs() {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [])

	return (
		<>
			<div className='about-us-page'>
				<AboutBanner />
				<Hayyat />
				<Benefit />
				<Faqat />
				<Shahdat />
			</div>
		</>
	)
}

export default AboutUs
