import React, { useEffect, useState } from 'react'
import Banner from "../../common/Banner/Banner"
import Hayyat from '../../common/Hayyat/Hayyat'
import AlimNabeela from '../../common/AlimNabeela/AlimNabeela'
import KaifTamil from '../../common/KaifTamil/KaifTamil'
import Aljadid from '../../common/Aljadid/Aljadid'
import Faqat from '../../common/Faqat/Faqat'
import Shahdat from '../../common/Shahadat/Shahdat'
import Faq from '../Faq/Faq'
import Loader from '../../../config/Loader'

const Home = () => {
	const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
 setIsLoading(true)
    window.scrollTo({ top: 0, behavior: "smooth" });
    const delay = 50;
		const timeoutId = setTimeout(() => {
			setIsLoading(false);
		}, delay);

		return () => {
			clearTimeout(timeoutId);
		};
  }, [])

  return (
    <>
      <Banner />
      <Hayyat />
      <div className='alim__wrapr '>
        <AlimNabeela />
      </div>
      <KaifTamil />
      <Aljadid />
      <Faqat />
      <Shahdat />
      <Faq/>
    </>
  )
}

export default Home
