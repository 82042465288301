import React, { useEffect } from 'react'
import CmsPagesbaner from './TermsBaner'
import CmsContent from './TermsContent'

function Terms() {

  useEffect(() => {
    window.scrollTo(0, 1)
  }, [])
  
  return (
    <div>
      <CmsPagesbaner />
      <CmsContent />
    </div>
  )
}

export default Terms
