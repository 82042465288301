import React, { useEffect, useState } from 'react'
import './dashboard.scss'
import calenderevent from "../../assets/images/bashbaord/calender-event.svg";
import calendertime from "../../assets/images/bashbaord/time-event.svg";
import { apiHelper } from '../../redux/apiHelper';
import userImage from "../../assets/images/user-image.png";
import { adminApiHelper } from '../../redux/adminApiHelper';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loader from '../../config/Loader';

function DashboardStates() {

     const userId = JSON.parse(localStorage.getItem('userId'));
     var format = { PM: "مساءً", AM: "أكون" }
     const date = new Date(); // Current date
     const tomorrow = new Date(date);
     tomorrow.setDate(date.getDate() + 1);
     const tomorrowDate = tomorrow.toISOString().split('T')[0]; // Tomorrow's date
     const seventhDay = new Date(date);
     seventhDay.setDate(date.getDate() + 7);
     const seventhDayDate = seventhDay.toISOString().split('T')[0]; // 7th day's date
     const currentDate = new Date().toISOString().split('T')[0];
     const [user, setUser] = useState({});
     const [consultations, setConsultations] = useState([]);
     const [oneweekConsultation, setOneWeekConsultations] = useState([]);
     const [dashboardCount, setDashboardCount] = useState();
     const [currentSubscription, setCurrentSubscription] = useState("");
     const [isLoading, setIsLoading] = useState(false);
     const months = [
          { en: 'January', ar: 'يناير' },
          { en: 'February', ar: 'فبراير' },
          { en: 'March', ar: 'مارس' },
          { en: 'April', ar: 'أبريل' },
          { en: 'May', ar: 'مايو' },
          { en: 'June', ar: 'يونيو' },
          { en: 'July', ar: 'يوليو' },
          { en: 'August', ar: 'أغسطس' },
          { en: 'September', ar: 'سبتمبر' },
          { en: 'October', ar: 'أكتوبر' },
          { en: 'November', ar: 'نوفمبر' },
          { en: 'December', ar: 'ديسمبر' }
     ];

     const userProfile = async () => {
          setIsLoading(true);
          let res = await apiHelper("get", `/auth/profile/${userId}`, '');
          if (res?.data) {
               const { data } = res;
               setUser(data?.user);
               setIsLoading(false);

          }
     };

     const getConsultation = async () => {
          const response = await adminApiHelper("get", `/consultations?from=${currentDate}&to=${currentDate}&userId=${userId}`);
          if (response?.data?.success) {
               setConsultations(response?.data?.consultations)
          }
     }

     const getOneWeekConsultations = async () => {
          const response = await adminApiHelper("get", `/consultations?from=${tomorrowDate}&to=${seventhDayDate}&userId=${userId}`);
          if (response?.data?.success) {
               setOneWeekConsultations(response?.data?.consultations)
          }
     }

     const getDashboardCount = async () => {
          const response = await apiHelper("get", `/dashboard?userId=${userId}`);
          setDashboardCount(response?.data)
     }

     const getCurrentSubscription = async () => {
          try {
               let res = await apiHelper(
                    "get",
                    `/subscriptions/current-subscription?userId=${userId}`,
                    ""
               );
               if (res?.data) {
                    const { data } = res;
                    setCurrentSubscription(data);
               }
          } catch (error) {

          }
     };

     useEffect(() => {
          setIsLoading(true);
          if (userId) {
               setIsLoading(false);
               userProfile(userId);
               getDashboardCount();
               getConsultation();
               getOneWeekConsultations();
               getCurrentSubscription();
          }
     }, [userId])

     return (
          <>{isLoading ? (
               <Loader />
          ) : 
               <div className='dashboard-states-wraper'>
                    <div className='states-days'>
                         <div className='dashboard-states'>
                              <div className='states-header'>
                              </div>
                              <div className='dashboard-states-content'>
                                   <div className='profile-picture'>
                                        <span>
                                             <img src={user?.profileImage ? `${process.env.REACT_APP_FILE_URL + user?.profileImage}` : userImage} alt="user-logo" />
                                        </span>
                                   </div>
                                   <h3 className='2xl:text-4xl xl:text-3xl md:text-2xl text-lg orange-color bold mt-3'>
                                        {user?.name}  مرحبا بعودتك في موقع م. نبيلة

                                        {currentSubscription?.subscription && currentSubscription?.isExpired ? <span className="items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-500 text-white">Subscription : Expired</span> : currentSubscription?.subscription && !currentSubscription?.isExpired ? <span className="items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-blue-500 text-white">Subscription : {currentSubscription?.subscription?.plan?.name}</span> : ""}
                                   </h3>
                                   <div className='grid cmd:grid-cols-3 sm:grid-cols-2 gap-4 state-items'>
                                        <div className='stats-info xl:mt-5 mt-3'>
                                             <h5 className='orange-color'>{dashboardCount?.events}</h5>
                                             <p className='2xl:text-3xl xl:text-2xl lg:text-lg text-base font-light dark_color'>اللقاءات المفتوحة</p>
                                        </div>
                                        <div className='stats-info xl:mt-5 mt-3'>
                                             <h5 className='orange-color'>{dashboardCount?.consultations}</h5>
                                             <p className='2xl:text-3xl xl:text-2xl lg:text-lg text-base font-light dark_color'>استشاراتي</p>
                                        </div>
                                        <div className='stats-info xl:mt-5 mt-3'>
                                             <h5 className='orange-color'>{dashboardCount?.courses}</h5>
                                             <p className='2xl:text-3xl xl:text-2xl lg:text-lg text-base font-light dark_color'>دوراتي</p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='calander-days common-white-bgcard xl:mt-10 lg:mt-5 mt-3'>
                              <h3 className='2xl:text-4xl xl:text-3xl md:text-2xl text-lg orange-color bold mt-3'>
                                   اليوم
                              </h3>
                              <div className='grid cmd:grid-cols-3 sm:grid-cols-2 gap-4 calandar-states mt-5'>
                                   {consultations?.length ? consultations?.map((consultation, index) => {
                                        const customDate = moment(`${consultation?.date}`).format('YYYY-MM-DD')
                                        const timeArr = moment(`${customDate} ${consultation?.start_time}`).format('hh:mm A').split(' ')
                                        const endTimeArr = moment(`${customDate} ${consultation?.end_time}`).format('hh:mm A').split(' ')
                                        const start_time = timeArr[0]
                                        const end_time = endTimeArr[0]
                                        const timeFormat = format[timeArr[1]]
                                        const endTimeFormat = format[endTimeArr[1]]
                                        return (
                                             <div key={index} className='calander-qoutes rounded light-tan'>
                                                  <div className='cl-dates flex justify-between'>
                                                       <p><span className='lato f-size-25'>{start_time}</span>{timeFormat}</p>
                                                       <p>-</p>
                                                       <p><span className='lato f-size-25'>{end_time}</span>{endTimeFormat} </p>
                                                  </div>
                                                  <div className='flex justify-center qoute-name'>
                                                       <h4>{consultation?.questions}</h4>
                                                  </div>
                                             </div>
                                        )
                                   }) : "لم يتم العثور على استشارة"}
                              </div>
                         </div>
                    </div>
                    <div className='consultation-time'>
                         <div className='consult-header'>
                              <h3 className="2xl:text-4xl xl:text-3xl md:text-2xl text-lg text-white bold">احجز جلسة الاستشارة المناسبة لك</h3>
                              <Link to="/consultation" className="buton-holder flex justify-end items-center mt-3">
                                   <button className="theme-btn bg-white"> احجز الآن</button>
                              </Link>
                         </div>
                         <div className='event-details '>
                              <ul className='event--list'>
                                   {oneweekConsultation && oneweekConsultation?.map((consultation, index) => {
                                        const consultationDate = new Date(consultation?.date); // Assuming the date field is named "date"
                                        const consultationMonthIndex = consultationDate?.getMonth();
                                        const consultationMonth = months[consultationMonthIndex]?.ar;
                                        const customDate = moment(`${consultation?.date}`).format('YYYY-MM-DD')
                                        const timeArr = moment(`${customDate} ${consultation?.start_time}`).format('hh:mm A').split(' ')
                                        const endTimeArr = moment(`${customDate} ${consultation?.end_time}`).format('hh:mm A').split(' ')
                                        const start_time = timeArr[0]
                                        const end_time = endTimeArr[0]
                                        const timeFormat = format[timeArr[1]]
                                        const endTimeFormat = format[endTimeArr[1]]
                                        return (
                                             <li>
                                                  <div key={index} className='event--list-info'>
                                                       <h5 className='light-yellow font-bold f-size-22'> {consultation?.questions}</h5>
                                                       <div className='calander-row xl:mb-3 mb-2 xl:pt-3 pt-2'>
                                                            <span className='image-wraper'>
                                                                 <img src={calenderevent} alt="site_logo" />
                                                            </span>
                                                            <p><span className='lato xl:p-3 p-1'>{consultationDate?.getDate()}</span> <span className='xl:p-3 p-1dark_color xl:text-lg lg:text-base text-sm'>{consultationMonth}</span> <span className='lato'>{consultationDate?.getFullYear()}</span></p>
                                                       </div>
                                                       <div className='calander-row'>
                                                            <span className='image-wraper'>
                                                                 <img src={calendertime} alt="site_logo" />
                                                            </span>
                                                            <p>
                                                                 <span className='lato xl:p-3 p-1'>{start_time} </span><span className='dark_color xl:text-lg lg:text-base text-sm'>{timeFormat}</span>
                                                                 <span className='lato xl:p-3 p-1'>{end_time} </span><span className='dark_color xl:text-lg lg:text-base text-sm'>{endTimeFormat}</span>
                                                            </p>
                                                       </div>
                                                  </div>
                                             </li>
                                        )
                                   })}
                              </ul>
                         </div>
                    </div>
               </div>
          }
          </>
     )
}

export default DashboardStates
