import React from 'react';
import { Link } from 'react-router-dom';

const Columns = () => {
   const columns = [
      {
         name: 'عنوان الدورة',
         selector: row => row?.id?.title,
         sortable: true,
      },
      {
         name: 'السعر',
         selector: row => `$ ${row?.id?.price}`,
         sortable: true,
      },
      {
         name: 'الفئة',
         selector: row => row?.id?.type,
         sortable: true,
      },
      {
         name: 'تاريخ الاشتراك',
         selector: row => row?.id?.category,
         sortable: true,
      },
      {
         name: 'تاريخ انتهاء الاشتراك',
         selector: row => {
            return (
               <div className='views_btn_wraper'>
                  <Link to={`/course/${row?.id?._id}`}>
                  <button className="theme-btn theme_views_btn">
                     عرض
                  </button>
                  </Link>
               </div>
            );
         },
      }
   ];

   return columns;
};

export default Columns;
