import { Link } from "react-router-dom";

const Columns = () => {
   
   const columns = [
      {
         name: 'نوع الاستشارة',
         selector: row => row?.name,
         sortable: true,
      },
      // {
      //    name: 'رقم الهاتف',
      //    selector: row => `${row?.phone}`,
      //    sortable: true,
      // },
      // {
      //    name: 'بريد إلكتروني',
      //    selector: row => `${row?.email}`,
      //    sortable: true,
      // },
      {
         name: 'حالة الاستشارة',
         selector: row => `${row?.status}`,
         sortable: true,
      },
      {
         name: 'تاريخ الاستشارة',
         selector: row => `${row?.date}`,
         sortable: true,
      },
      {
         name: 'وقت بدء الاستشارة',
         selector: row => row?.start_time,
         sortable: true,
      },
      {
         name: 'وقت انتهاء الاستشارة',
         selector: row => row?.end_time,
         sortable: true,
      },
      {
      name:  ' رابط الاستشارة',
      selector: row => {
         return (
           <>{row?.status === "Accepted" && row?.paymentStatus === true?
           <div className="flex">
             <div className='views_btn_wraper'>
             <Link to={row?.zoom} target="_blank">
               <button className="theme-btn theme_views_btn">
                الزوم
               </button>  
               </Link>           
            </div>
            <div className='views_btn_wraper mr-3'>
            <Link to={row?.telegram} target="_blank">
            <button className="theme-btn theme_views_btn border_radi">
             التلغرام
            </button>   
            </Link>       
         </div>
         </div>
         :""}
         </> 
         );
      },
      },
   ];
   return columns;
};

export default Columns;
