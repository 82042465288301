import React, { useState } from 'react';
import Eye from "../../../../assets/images/eye.svg"
import UserIcon from "../../../../assets/images/forget-icon.svg"
import { Link, useNavigate } from "react-router-dom"
import site_logo from "../../../../assets/images/site_logo.png";
import "../Auth.scss"
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { resetPassword } from '../../../../redux/auth/authActions';

function ResetPassword() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userPhone = useSelector(state => state.auth?.user?.phone);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);


    const schema = yup.object().shape({
        password: yup.string().required('كلمة المرور مطلوبة').min(5, 'قصير جدا').max(30, 'طويل جدا'),
        confirmPassword: yup.string().required('كلمة المرور مطلوبة').oneOf([yup.ref('password'), null], 'يجب أن تتطابق كلمات المرور')
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const handleFormSubmit = (data) => {
        data.phone = userPhone
        dispatch(resetPassword(data, navigate));
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };


    return (
        <div>
            <div className='forget_wraper'>
                <div className='login_In relative'>
                    <div className='site-logo desktop-block mobile-hide'>
                        <Link to="/" className="site__logo pl-14">
                            <img src={site_logo} alt="site_logo" className='m-auto z-10 relative pt-6' />
                        </Link>
                    </div>
                    <div className='login-in_wraper 2xl:py-10 lg:py-4 pt-2 pb-12  z-10 relative'>
                        <div className='theme__container flex'>
                            <div className='heading-content bg-white'>
                                <div className='auth-heading flex justify-between items-center 2xl:pt-16 md:pt-10 pt-2'>
                                    <h1 className='2xltext-4xl md:text-2xl text-xl font-bold'>إعادة تعيين كلمة المرور الخاصة بك</h1>
                                    <div className='user__icon'>
                                        <img src={UserIcon} alt="user" />
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    <div className='form-box flex flex-col'>
                                        <label className='dark-color f-size-17 font-bold pb-4'>رقم التليفون<sup className='staric'>*</sup></label>
                                        <input type="text" placeholder='أكتب هنا' name="phone" value={userPhone ? userPhone : ''} disabled />
                                    </div>
                                    <div className='form-box flex flex-col mt-5'>
                                        <label className='dark-color f-size-17 font-bold pb-4'>كلمه السر<sup className='staric'>*</sup></label>
                                        <div className='relative'>
                                            <input type={showPassword ? "text" : "password"} placeholder="ادخل رقمك السري" className='form-control' {...register("password")} />
                                            <button type="button" className='eye-button' onClick={handleTogglePassword}>
                                        {showPassword ?  <div className='slash-icon' > <img src={Eye} alt="eye" /></div> :<img src={Eye} alt="eye" />}
                                        </button>
                                            <span className='color-red'>{errors.password?.message}</span>
                                        </div>
                                    </div>
                                    <div className='form-box flex flex-col mt-5'>
                                        <label className='dark-color f-size-17 font-bold pb-4'>تأكيد كلمة السر<sup className='staric'>*</sup></label>
                                        <div className='relative'>
                                            <input type={showNewPassword ? "text" : "password"} placeholder="ادخل رقمك السري" className='form-control' {...register("confirmPassword")} />
                                            <button type="button" className='eye-button' onClick={handleToggleNewPassword}>
                                             {showNewPassword ?  <div className='slash-icon' > <img src={Eye} alt="eye" /></div> :<img src={Eye} alt="eye" />}
                                        </button>
                                            <span className='color-red'>{errors.confirmPassword?.message}</span>
                                        </div>
                                    </div>
                                    <div className='flex items-center mt-12 justify-between'>
                                        <div className="theme-btn flex justify-center items-center ">
                                            <button className="flex justify-center items-center" onClick={handleFormSubmit}>حفظ</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='auth-left_banner'>
                                <div className='login-text-left'>
                                    <h2 className=''>لا تنس أبدًا قيمك</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
