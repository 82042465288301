import React, { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { courseListing } from '../../../redux/course/courseActions';
import Loader from '../../../config/Loader';

function AlimTabs() {

    const dispatch = useDispatch();
    const coursesData = useSelector(state => state.course.courses);
    const [isLoading, setIsLoading] = useState(false);
    const [showAllCourses, setShowAllCourses] = useState(false);
    const [showCourses, setShowCourses] = useState(false);
    const [showAllRecoveryCourses, setShowAllRecoveryCourses] = useState(false);
    const defaultCategory = "Recovery";

    const fetchCourses = (category) => {
        setIsLoading(true);
        dispatch(courseListing(category)).finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(courseListing(defaultCategory)).finally(() => setIsLoading(false));
        window.scroll(0, 0)
    }, []);

    return (
        <div className='mt-10 tabs-ali-wraper'>
            <Tab.Group >
                <Tab.List>
                    <Tab onClick={() => fetchCourses("Recovery")}>
                        <div className='rectangle_img ml-24 relative '>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="232.684" height="228.651" viewBox="0 0 232.684 228.651">
                                    <g id="Group_1830" data-name="Group 1830" transform="translate(1164.342 -3183.282)">
                                        <g id="Rectangle_40" data-name="Rectangle 40" transform="translate(-1160.309 3328.241) rotate(-60)" fill="none" stroke="#d8dddd" strokeWidth="1">
                                            <rect width="167.384" height="167.384" rx="4" stroke="none" />
                                            <rect x="0.5" y="0.5" width="166.384" height="166.384" rx="3.5" fill="none" />
                                        </g>
                                        <rect className='fill_tab' id="Rectangle_37" data-name="Rectangle 37" width="155.428" height="155.428" rx="4" transform="translate(-1164.342 3297.608) rotate(-45)" fill="#ded4d6" />
                                    </g>
                                </svg>
                            </div>
                            <div className='absolute animated-image lastanimated'>
                                <lottie-player src="https://lottie.host/f6dc2310-2689-433e-a078-9089fd818feb/3J23le2npa.json" background="transparent" speed="1" class="lotteplayer" style={{ width: '190px', height: '170px' }} loop autoplay></lottie-player>
                            </div>
                            <h6 className=' alwai 2xl:text-3xl lg:text-2xl text-lg text-white  2xl:text-5xl lg:text-3xl text-lg pt-6 '>التشافي</h6>
                        </div>
                    </Tab>
                    <Tab onClick={() => fetchCourses("Meditation")}>
                        <div className='rectangle_img  ml-24 relative  '>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="232.684" height="228.651" viewBox="0 0 232.684 228.651">
                                    <g id="Group_1830" data-name="Group 1830" transform="translate(1164.342 -3183.282)">
                                        <g id="Rectangle_40" data-name="Rectangle 40" transform="translate(-1160.309 3328.241) rotate(-60)" fill="none" stroke="#d8dddd" strokeWidth="1">
                                            <rect width="167.384" height="167.384" rx="4" stroke="none" />
                                            <rect x="0.5" y="0.5" width="166.384" height="166.384" rx="3.5" fill="none" />
                                        </g>
                                        <rect className='fill_tab' id="Rectangle_37" data-name="Rectangle 37" width="155.428" height="155.428" rx="4" transform="translate(-1164.342 3297.608) rotate(-45)" fill="#ded4d6" />
                                    </g>
                                </svg>
                            </div>
                            <div className='absolute animated-image lastanimated'>
                                <lottie-player src="https://lottie.host/fc8c8742-59f9-446c-8247-ba9d8f261835/mQohJKrNt6.json" background="transparent" speed="1" class="lotteplayer" style={{ width: '190px', height: '170px' }} loop autoplay></lottie-player>
                            </div>
                            <h6 className=' alwai 2xl:text-5xl lg:text-4xl text-lg text-white text-3xl pt-6 '>التأمل</h6>
                        </div>
                    </Tab>
                    <Tab onClick={() => fetchCourses("Awareness")}>
                        <div className='rectangle_img relative'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="232.684" height="228.651" viewBox="0 0 232.684 228.651">
                                    <g id="Group_1830" data-name="Group 1830" transform="translate(1164.342 -3183.282)">
                                        <g id="Rectangle_40" data-name="Rectangle 40" transform="translate(-1160.309 3328.241) rotate(-60)" fill="none" stroke="#d8dddd" strokeWidth="1">
                                            <rect width="167.384" height="167.384" rx="4" stroke="none" />
                                            <rect x="0.5" y="0.5" width="166.384" height="166.384" rx="3.5" fill="none" />
                                        </g>
                                        <rect className='fill_tab' id="Rectangle_37" data-name="Rectangle 37" width="155.428" height="155.428" rx="4" transform="translate(-1164.342 3297.608) rotate(-45)" fill="#ded4d6" />
                                    </g>
                                </svg>
                            </div>
                            <div className='absolute animated-image lastanimated'>
                                <lottie-player src="https://lottie.host/9e0e1a7a-42e4-4532-a700-9a85472b705e/s9onSuZjeD.json" background="transparent" speed="1" class="lotteplayer" style={{ width: '190px', height: '170px' }} loop autoplay></lottie-player>
                            </div>
                            <h6 className=' alwai 2xl:text-5xl lg:text-4xl text-lg text-white text-3xl pt-6 '>الوعي</h6>
                        </div>
                    </Tab>
                </Tab.List>
                <Tab.Panels >
                    <Tab.Panel>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <div className="grid cmd:grid-cols-3 xm:grid-cols-2 gap-4 mt-20 relative">
                                {coursesData?.courses?.length ?(<>
                                    {coursesData?.courses.slice(0, showAllCourses ? coursesData?.courses?.length : 6)?.map((course, index) => {
                                    const imageUrl = process.env.REACT_APP_FILE_URL + course?.thumbnailpic;
                                    const divStyle = {
                                        backgroundImage: `url(${imageUrl})`,
                                        backgroundRepeat: 'no-repeat'
                                    };
                                    return (
                                        <div className="tabs_conten_wraper" key={index}>
                                            <div className="coleague-wrap coleagesecond p-4" style={divStyle}>
                                                <div className="relative z-10">
                                                    <h5 className="text-white f-size-26 ">{course.title}</h5>
                                                    <div className=" text-start mt-3 mb-5">
                                                        <Link to={`/course/${course._id}`} className="theme-btn  ">انضم إلينا</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="colleage-text text-start f-size-17 mt-4">
                                                <h6 className="light-yellow text-2xl">{course.files?.length} ملفات المجموعة</h6>
                                                <p className=" font-light mt-2 max-w-md text-2xl">{course.shortDescription}</p>
                                            </div>
                                        </div>
                                    )
                                })} 
                                {coursesData?.courses?.length > 6 && !showAllCourses && (
                                    <div className='no_course_fd_btn'>
                                        <button className="theme-btn " onClick={() => setShowAllCourses(true)}>
                                            Load More
                                        </button>
                                    </div>

                                )} 
                                </>):
                                    <h1 className='orange-color no_course_fd' >لم يتم العثور على أي دورة</h1>
                                }
                            </div>
                        )}
                    </Tab.Panel>
                    <Tab.Panel>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <div>
                                <div className="grid cmd:grid-cols-3 xm:grid-cols-2 gap-4 mt-20 relative">
                                    {coursesData?.courses?.length ? (<>
                                    {coursesData?.courses?.slice(0, showCourses ? coursesData?.courses?.length : 6)?.map((course, index) => {
                                        const imageUrl = process.env.REACT_APP_FILE_URL + course?.thumbnailpic;
                                        const divStyle = {
                                            backgroundImage: `url(${imageUrl})`,
                                            backgroundRepeat: 'no-repeat'
                                        };
                                        return (
                                            <div className="tabs_conten_wraper" key={index}>
                                                <div className="coleague-wrap coleagesecond p-4" style={divStyle}>
                                                    <div className="relative z-10">
                                                        <h5 className="text-white f-size-26 ">{course.title}</h5>
                                                        <div className="mt-3 mb-5">
                                                            <Link to={`/course/${course._id}`} className="theme-btn">انضم إلينا</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="colleage-text text-start f-size-17 mt-4">
                                                    <h6 className="light-yellow text-2xl">{course.files?.length} ملفات المجموعة</h6>
                                                    <p className=" font-light mt-2 max-w-md text-2xl">{course.shortDescription}</p>
                                                </div>
                                            </div>
                                        )
                                    })}{coursesData?.courses?.length > 6 && !showCourses && (
                                        <div className='no_course_fd_btn'>
                                        <button className="theme-btn" onClick={() => setShowCourses(true)}>
                                            Load More
                                        </button>
                                        </div>
                                    )}
                                </>) :
                                        <h1 className='orange-color no_course_fd' >لم يتم العثور على أي دورة</h1>
                                    }
                                </div>
                            </div>

                        )}
                    </Tab.Panel>
                    <Tab.Panel>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <div className="grid cmd:grid-cols-3 xm:grid-cols-2 gap-4 mt-20 relative">
                                {coursesData?.courses?.length ? (<>
                                    {coursesData?.courses?.slice(0, showAllRecoveryCourses ? coursesData?.courses?.length : 6)?.map((course, index) => {
                                    const imageUrl = process.env.REACT_APP_FILE_URL + course?.thumbnailpic;
                                    const divStyle = {
                                        backgroundImage: `url(${imageUrl})`,
                                        backgroundRepeat: 'no-repeat'
                                    };
                                    return (
                                        <div className="tabs_conten_wraper" key={index}>
                                            <div className="coleague-wrap coleagesecond p-4" style={divStyle}>
                                                <div className="relative z-10">
                                                    <h5 className="text-white f-size-26 ">{course.title}</h5>
                                                    <div className="mt-3 mb-5 text-start">
                                                        <Link to={`/course/${course._id}`} className="theme-btn">انضم إلينا</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="colleage-text text-start f-size-17 mt-4">
                                                <h6 className="light-yellow text-2xl">{course.files?.length} ملفات المجموعة</h6>
                                                <p className=" font-light mt-2 max-w-md text-2xl">{course.shortDescription}</p>
                                            </div>
                                        </div>
                                    )
                                }) }{coursesData?.courses?.length > 6 && !showAllRecoveryCourses && (
                                    <div className='no_course_fd_btn'>
                                    <button className="theme-btn" onClick={() => setShowAllRecoveryCourses(true)}>
                                        Load More
                                    </button>
                                    </div>
                                )}
                            </>) :
                                    <h1 className='orange-color no_course_fd' >لم يتم العثور على أي دورة</h1>
                                }
                            </div>
                        )}
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

export default AlimTabs
