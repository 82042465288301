import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './sidebar.scss';
import logosvg from "../../../assets/images/bashbaord/logo-svg.png";
import { ReactComponent as LogoFirst } from '../../../assets/images/bashbaord/boximage.svg';
import { ReactComponent as SideBar1 } from "../../../assets/images/bashbaord/sidebarimg2.svg";
import { ReactComponent as SideBar2 } from "../../../assets/images/bashbaord/sidebarimg3.svg";
import { ReactComponent as SideBar3 } from "../../../assets/images/bashbaord/courses.svg";
import { ReactComponent as SideBar4 } from "../../../assets/images/bashbaord/sidebarimg5.svg";
import { ReactComponent as SideBar5 } from "../../../assets/images/bashbaord/sidebarimg6.svg";
import { ReactComponent as SideBar6 } from "../../../assets/images/bashbaord/sidebarimg7.svg";
import { ReactComponent as ShoppingCart } from "../../../assets/images/bashbaord/ShoppingCart.svg";
import { ReactComponent as Payements } from "../../../assets/images/bashbaord/payment.svg";
import { ReactComponent as Plans } from "../../../assets/images/bashbaord/plans.svg";
import { ReactComponent as Subscriptions } from "../../../assets/images/bashbaord/subscriptions.svg";


import { ReactComponent as ProfileIcon } from "../../../assets/images/bashbaord/ProfileIcon.svg";
import { ReactComponent as Signout } from "../../../assets/images/bashbaord/signout.svg";

const Sidebar = () => {

    const navigate = useNavigate(true)
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(true);
    const [activePage, setActivePage] = useState('');

    const handleMenuClick = (page) => {
        setActivePage(page);
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('userId');
        localStorage.removeItem('timezone');
        localStorage.removeItem('items');
        navigate('/');
    }

    return (
        <>
            <div className={`sidebar ${isOpen ? 'close' : 'open'}`}>
                <button className="toggle-button" onClick={handleToggle}>
                    {isOpen ? (
                        <FontAwesomeIcon icon={faBars} />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} />
                    )}
                </button>
                <div className='logo-wraper'>
                    <Link to="/" className="site-logo">
                        <img src={logosvg} alt="site_logo" className='w-50' />
                    </Link>
                </div>
                <div className='dashboard-pages'>
                    <ul className="menu">
                        <li>
                            <Link
                                to="/dashboard"
                                className={location.pathname === '/dashboard' ? 'menu-item active' : 'menu-item'}
                                onClick={() => handleMenuClick('dashboard')}
                            >
                                <span>
                                    <LogoFirst className="ml-6" />
                                </span>
                                لوحة القيادة
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/profile"
                                className={location.pathname === '/profile' ? 'menu-item active' : 'menu-item'}
                                onClick={() => handleMenuClick('profile')}
                            >
                                <span>
                                    <ProfileIcon className="ml-6" />
                                </span>
                                معلوماتي الشخصية
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/user-courses"
                                className={location.pathname === '/user-courses' ? 'menu-item active' : 'menu-item'}
                                onClick={() => handleMenuClick('user-courses')}
                            >
                                <span>
                                    <SideBar3 className="ml-6" />
                                </span>
                                دوراتي
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/user-events"
                                className={location.pathname === '/user-events' ? 'menu-item active' : 'menu-item'}
                                onClick={() => handleMenuClick('user-events')}
                            >
                                <span>
                                    < SideBar6 className="ml-6" />
                                </span>
                                اللقاءات المفتوحة
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/user-consultations"
                                className={location.pathname === '/user-consultations' ? 'menu-item active' : 'menu-item'}
                                onClick={() => handleMenuClick('user-consultations')}
                            >
                                <span>
                                    <SideBar5 className="ml-6" />
                                </span>
                                استشاراتي
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/payments"
                                className={location.pathname === '/payments' ? 'menu-item active' : 'menu-item'}
                                onClick={() => handleMenuClick('payments')}
                            >
                                <span>
                                    <Payements className="ml-6" />
                                </span>
                                مدفوعاتي
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/plans"
                                className={location.pathname === '/plans' ? 'menu-item active' : 'menu-item'}
                                onClick={() => handleMenuClick('plans')}
                            >
                                <span>
                                    <Plans className="ml-6" />
                                </span>
                                باقة اشتراكي
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/subscriptions"
                                className={location.pathname === '/subscriptions' ? 'menu-item active' : 'menu-item'}
                                onClick={() => handleMenuClick('subscriptions')}
                            >
                                <span>
                                    <Subscriptions className="ml-6" />
                                </span>
                                اشتراكي
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/contact-us"
                                className={location.pathname === '/contact-us' ? 'menu-item active' : 'menu-item'}
                                onClick={() => handleMenuClick('contact-us')}
                            >
                                <span>
                                    <SideBar2 className="ml-6" />
                                </span>
                                تواصل معنا
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/articles"
                                className={location.pathname === '/articles' ? 'menu-item active' : 'menu-item'}
                                onClick={() => handleMenuClick('articles')}
                            >
                                <span>
                                    <SideBar1 className="ml-6" />
                                </span>
                                مقالات
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/aboutus"
                                className={location.pathname === '/aboutus' ? 'menu-item active' : 'menu-item'}
                                onClick={() => handleMenuClick('aboutus')}
                            >
                                <span>
                                    <SideBar4 className="ml-6" />
                                </span>
                                عالم نبيلة
                            </Link>
                        </li>
                    </ul>
                    <div className='singout'>
                        <button
                            className={activePage === 'singout' ? 'menu-item active' : 'menu-item'}
                            onClick={logOut}
                        >
                            <span className='white-circle ml-6'>
                                <Signout className="" />
                            </span>
                            تسجيل خروج
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
