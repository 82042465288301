import { Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Loader from "../config/Loader";
import { apiHelper } from "../redux/apiHelper";

const Middleware = ({ children }) => {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const token = JSON.parse(localStorage.getItem("token")); // Get the token from localStorage
  useEffect(() => {
    const checkTokenValidity = async () => {
      if (token) {
        try {
          const response = await apiHelper('post','/auth/verify-token', {token} );
          if (response?.data?.success) {
            setIsTokenValid(true);
          } else {
            setIsTokenValid(false);
            localStorage.removeItem("token");
          }
        } catch (error) {
          console.error("Error verifying token:", error);
          setIsTokenValid(false);
          localStorage.removeItem("token");
        }
      } else {
        setIsTokenValid(false);
      }
      setIsLoading(false);
    };

    checkTokenValidity();
  }, [token]);

  if (isLoading) {
    return <Loader/>; // Render a loading indicator while checking the token validity
  }

  if (!isTokenValid) {
    return <Navigate to="/" />;
  }

  return children;
};

export default Middleware;
