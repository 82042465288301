import React from 'react'

function CmsContent() {
    return (
        <div>
            <div className='cms-content-wraper cart--items-sec xl:py-14 md:py-10 sm:py-8 py-6 '>
                <div className="theme__container">
                    <h3 className='2xl:text-4xl xl:text-3xl lg:text-2xl text-xl  dark_color bold mb-5 text-start'>سياسية الخصوصية </h3>
                    <p className=' xl:text-3xl md:text-2xl text-xl text-justify mb-2 mt-2 dark_color'>
                        نود أن نعلمك بأن موقع المهندسة نبيلة يحترم خصوصيتك وهو مؤهل لحماية بياناتك الشخصية. وفي سياسة الخصوصية هذه نوضح  طريقة جمع معلوماتك وطريقة استخدامها. تعتبر هذه السياسة بمثابة ميثاق بينك وبين موقع المهندسة نبيلة لذا نرجو منك قراءته بتمعن لتستمتع بتجربة فريدة أثناء رحلتك معنا.
                    </p>
                    <div className='terms-main-heading'>
                        <h3 className='2xl:text-4xl xl:text-3xl lg:text-2xl text-xl  dark_color bold mb-5 text-start mt-6'>
                            ماهي المعلومات التي يتم جمعها؟
                        </h3>
                        <p className=' xl:text-3xl md:text-2xl text-xl text-justify mb-2 mt-2 dark_color'>
                            يتم جمع البيانات الخاصة بك عند التسجيل في موقع المهندسة نبيلة وإنشاء الملف التعريفي الخاص بك أو من خلال الاشتراك (الشهري أو السنوي) أو أثناء شراء احدى الدورات أو عند اشتراكك في قائمة عائلة المهندسة نبيلة. وتتضمن هذه البيانات الاسم، رقم التواصل، البريد الالكتروني
                        </p>
                    </div>
                    <div className='terms-main-heading'>
                        <h3 className='2xl:text-4xl xl:text-3xl lg:text-2xl text-xl  dark_color bold mb-5 text-start mt-6'>
                            ما الهدف من جمع هذه المعلومات؟
                        </h3>
                        <p className=' xl:text-3xl md:text-2xl text-xl text-justify mb-2 mt-2 dark_color'>
                            الهدف الأساسي من جمع هذه المعلومات هو معرفتك بشكل أفضل لتقديم تجربة فريدة من نوعها أثناء رحلتك معنا حيث نحرص على تقديم الخيارات المناسبة لك والتواصل معك بالطريقة المفضلة لديك.
                        </p>
                        <p className=' xl:text-3xl md:text-2xl text-xl text-justify mb-2 mt-2 dark_color'>
                            كما يتم استخدام هذه المعلومات للدراسات والإحصاءات بعد معالجتها وتحويلها إلى مجهولة الهوية حفظا لخصوصيتك
                        </p>
                    </div>
                    <div className='terms-main-heading'>
                        <h3 className='2xl:text-4xl xl:text-3xl lg:text-2xl text-xl  dark_color bold mb-5 text-start mt-6'>
                            اتصل بنا
                        </h3>
                        <p className=' xl:text-3xl md:text-2xl text-xl text-justify mb-2 mt-2 dark_color'>
                            إذا كان لديك أي أسئلة أو شكاوى متعلقة بسياسة الخصوصية، يمكنك التواصل عبر تطبيق الواتس آب على الرقم التالي
                            0097339078840

                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CmsContent
