import { REGISTER_USER, LOGIN_USER, VERIFY_CODE, FORGET_PASSWORD, VERIFY, RESET_PASSWORD, TOGGLE_STATE, ERROR_STATE } from "./authTypes";
import { toast } from "react-toastify";
import { ENV } from "./../../config/config";
import { apiHelper } from "../apiHelper"

export const RegisterUser = (user, navigate) => async (dispatch) => {
  try {
    let res = await apiHelper("post", `/auth/signup`, user)
    if (res?.data) {
      let { data } = res
      console.log("RegisterUser ~ data:", data)
      toast.success(data?.message)
      dispatch({
        type: REGISTER_USER,
        payload: data
      })
      navigate('/verify-code')
    }
    else {
      dispatch({
        type: ERROR_STATE
      })
    }
  } catch (error) {
    toast.error(error.response.data.message)
  }
}

export const VerifyPhoneCode = (data, navigate) => async (dispatch) => {
  try {
    let res = await apiHelper("post", `/auth/verify-code`, data)
    if (res?.data) {
      let { data } = res
      toast.success(data.message)
      ENV.encryptUserData(data.user, data.token, data.user.id)
      dispatch({
        type: VERIFY_CODE,
        payload: data
      })
      navigate('/')
    }
    else {
      dispatch({
        type: ERROR_STATE
      })
    }
  } catch (error) {
    toast.error(error.response.message)
  }
}

export const loginUser = (user, navigate) => async (dispatch) => {
  try {
    let res = await apiHelper("post", `/auth/login`, user)
    if (res?.data) {
      let { data } = res
      // console.log("authActions.js:57 ~ loginUser ~ data:", data)

      if (user?.phone.includes('@')) {
        if (data?.user.emailVerified === false) {
          toast.warning(data.message);
          return;
        }
      } else if (data?.user.phoneVerified === false) {
        toast.warning(data.message);
        navigate('/verify-code');
        return;
      }
      ENV.encryptUserData(data.user, data.token, data.user.id);
      navigate('/');
      dispatch({
        type: LOGIN_USER,
        payload: data
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const forgetPassword = (data, navigate) => async (dispatch) => {
  try {
    let res = await apiHelper("post", `/auth/forgot-password`, data)
    if (res?.data) {
      console.log("authActions.js:72 ~ forgetPassword ~ res:", res?.data)
      let { data } = res
      dispatch({
        type: FORGET_PASSWORD,
        payload: data
      })
      navigate('/verify')
    }
    else {
      dispatch({
        type: ERROR_STATE
      })
    }
  } catch (error) {
    toast.error(error.response.data.message)
  }
}

export const verifyForgetCode = (data, navigate) => async (dispatch) => {
  try {
    let res = await apiHelper("post", `/auth/forgot-verify-code`, data)
    if (res?.data) {
      let { data } = res
      dispatch({
        type: VERIFY,
        payload: data
      })
      navigate('/reset-password')
    }
    else {
      dispatch({
        type: ERROR_STATE
      })
    }
  } catch (error) {
    toast.error(error.response.data.message)
  }
}

export const resetPassword = (data, navigate) => async (dispatch) => {
  try {
    let res = await apiHelper("post", `/auth/reset-password`, data)
    if (res?.data) {
      let { data } = res
      toast.success(data.message)
      dispatch({
        type: RESET_PASSWORD,
        payload: data
      })
      navigate('/')
    }
    else {
      dispatch({
        type: ERROR_STATE
      })
    }
  } catch (error) {
    toast.error(error.response.data.message)
  }
}

export const updateState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_STATE
    })
  } catch (error) {
    console.log(error.response.message)
  }
}
