import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminApiHelper } from "../../../redux/adminApiHelper";
import { toast } from "react-toastify";
import { apiHelper } from "../../../redux/apiHelper";
import Loader from "../../../config/Loader";

function List() {
  const navigate = useNavigate();
  const [plans, setPlans] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const userData = JSON.parse(localStorage.getItem("user"));
  let token = localStorage.getItem("token");
  const fullName = userData?.name;
  const namesArray = fullName?.split(" ");
  let firstName;
  if (namesArray && namesArray.length > 0) {
    firstName = namesArray[0];
  }
  let lastName;
  namesArray?.length > 1 ? (lastName = namesArray[1]) : (lastName = "");
  const subscribeSubmit = async (planId) => {
    setIsLoading(true);
    try {
      const payload = {
        planId: planId,
        userId: userId,
        currency: "USD",
        firstName: firstName,
        lastName: lastName,
        email: userData?.email,
        startDate: new Date(),
      };
      let res = await apiHelper("post", `/subscriptions`, payload);
      if (res?.data) {
        const { data } = res;
        if (data && data.paymentLink) {
          window.location.href = data.paymentLink;
        }
      }
    } catch (error) {
      toast.error(error.res.message);
    } finally {
      setIsLoading(false);
    }
  };

  var typesArr = {
    "monthly": "شهر واحد",
    "quarterly": "ثلاثة أشهر",
    "half-yearly": "ستة أشهر",
    "yearly": "سنة واحدة",
  }

  const cancelSubscription = async () => {
    try {
      let res = await apiHelper(
        "get",
        `/subscriptions/cancel-subscription?userId=${userId}`,
        ""
      );
      if (res?.data) {
        const { data } = res;
        toast.success(data?.message);
        fetchPlans();
      }
    } catch (error) {
      toast.error(error.res.message);
    }
  };

  const fetchPlans = async () => {
    setIsLoading(true);
    try {
      const res = await adminApiHelper(
        "get",
        `/plans?status=${true}&pagination=${false}`,
        ""
      );
      if (res?.data) {
        const { data } = res;
        setPlans(data?.plans);
      }
    } catch (error) {
      console.error(error)
    }

    try {
      if (userId) {
        let res = await apiHelper(
          "get",
          `/subscriptions/current-subscription?userId=${userId}`,
          ""
        );
        if (res?.data) {
          const { data } = res;
          setCurrentSubscription(data?.subscription);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPlans();
  }, []);

  return (
    <>
      <div className="theme__container ">
        <div className="cms-wraper">
          <div className="cms-pages-baner chechout_banner">
            <div className="theme__container">
              <div className="">
                <h2 className="2xl:text-8xl xl:text-7xl lg:text-5xl text-5xl  dark_color bold mb-5 text-center">
                  باقات الاشتراك
                </h2>
                <p className="2xl:text-3xl xl:text-2xl md:text-lg text-3xl font-light dark_color text-center ">
                  اختر الباقة المناسبة لك ضمن أنواع الباقات المتاحة
                </p>
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="">
            <div className=" plans_cards ">
              {plans?.map((plan, idx) => {
                const discount = Math.floor(
                  (plan?.price * plan?.discount) / 100
                );
                return (
                  <div
                    key={idx}
                    className="benefits_card  plan_subscribe_card text-center ml-1 mt-6 mb-4"
                  >
                    <h5 className="text-[42px]  dark-shadow plan_name">
                      {plan?.name}
                    </h5>
                    {plan?.discount > 0 ? (
                      <div>
                        <h6 className="text-[70px] dark-shadow line_height_64">
                          <sup className="plan_sup">$</sup>
                          {plan?.price - discount}
                        </h6>
                        <span className="minus-price ml-2">
                          {plan?.price} $
                        </span>{" "}
                        {plan?.discount} % -
                      </div>
                    ) : (
                      <h6 className="text-[70px] dark-shadow line_height_64">
                        <sup className="plan_sup">$</sup>
                        {plan?.price}
                      </h6>
                    )}
                    <h4 className="2xl:my-2 lg:my-4 my-2 2xl:text-4xl lg:text-2xl orange-color">
                      {typesArr[plan?.type]}
                    </h4>
                    <p className="f-size-27 dark-shadow plan_description">
                      {plan?.description}
                    </p>
                    {token ? (
                      plan?._id == currentSubscription?.plan?._id &&
                      currentSubscription?.status ? (
                        <div>
                          <span className="items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-blue-500 text-white">
                            مشترك
                          </span>
                        </div>
                      ) : (
                        <button
                          className="theme-btn mt-5 plan_subscribe_btn "
                          onClick={() => subscribeSubmit(plan?._id)}
                        >
                          اشترك الآن
                        </button>
                      )
                    ) : (
                      <div className=" mt-10">
                        <button
                          className="theme-btn"
                          onClick={() => navigate("/login")}
                        >
                          تسجيل الدخول
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default List;
