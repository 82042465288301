import { GET_ALL_COURSES, GET_COURSE, TOGGLE_STATE, ERROR_STATE, GET_SLIDER_COURSES } from "./courseTypes";
import { toast } from "react-toastify";
import { adminApiHelper } from "../adminApiHelper"

export const courseListing = (category) => async (dispatch) => {
  try {
    let res = await adminApiHelper("get", `/courses/?category=${category}&pagination=${false}`, '')
    if (res?.data) {
      let { data } = res
      dispatch({
        type: GET_ALL_COURSES,
        payload: data
      })
    }
    else {
      dispatch({
        type: ERROR_STATE
      })
    }
  } catch (error) {
    toast.error(error.response?.data?.message)
  }
}

export const sliderCoursesListing = () => async (dispatch) => {
  try {
    let res = await adminApiHelper("get", `/courses/?pagination=${false}`, '')
    if (res?.data) {
      let { data } = res
      dispatch({
        type: GET_SLIDER_COURSES,
        payload: data
      })
    }
    else {
      dispatch({
        type: ERROR_STATE
      })
    }
  } catch (error) {
    toast.error(error.response?.data?.message)
  }
}

export const getCourse = (id) => async (dispatch) => {
  try {
    let res = await adminApiHelper("get", `/courses/${id}`, '')
    if (res?.data) {
      let { data } = res
      dispatch({
        type: GET_COURSE,
        payload: data
      })
    }
    else {
      dispatch({
        type: ERROR_STATE
      })
    }
  } catch (error) {
    toast.error(error.response?.data?.message)
  }
}

export const updateState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_STATE
    })
  } catch (error) {
    console.log(error.response.data.message)
  }
}
