import { GET_ALL_COURSES, GET_COURSE, TOGGLE_STATE, ERROR_STATE, GET_SLIDER_COURSES } from './courseTypes';

const initialState = {
   course: null,
   courses: [],
   sliderCourses: [],
   success: false,
   error: false,
}

const courseReducer = (state = initialState, action) => {
   switch (action.type) {
      case GET_ALL_COURSES:
         return {
            ...state,
            courses: action.payload,
            success: true
         }
      case GET_SLIDER_COURSES:
         return {
            ...state,
            sliderCourses: action.payload,
            success: true
         }
      case GET_COURSE:
         return {
            ...state,
            course: action.payload?.course,
            success: true
         }
      case TOGGLE_STATE:
         return {
            ...state,
            error: false
         }
      case ERROR_STATE:
         return {
            ...state,
            error: true
         }
      default:
         return state
   }
}

export default courseReducer
