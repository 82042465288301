import { REGISTER_USER, LOGIN_USER, VERIFY_CODE, FORGET_PASSWORD, VERIFY, RESET_PASSWORD, TOGGLE_STATE, ERROR_STATE } from './authTypes';

const initialState = {
   user: null,
   success: false,
   registered: false,
   error: false,
   isLoggedIn: false,
   isVerify: false
}

const authReducer = (state = initialState, action) => {
   switch (action.type) {
      case REGISTER_USER:
         return {
            ...state,
            user: action.payload,
            success: true,
            registered: true,
            error: false
         }
      case LOGIN_USER:
        // Get the current timezone in the user's time zone
        const options = { timeZoneName: 'long' };
        const currentTimeZone = Intl.DateTimeFormat(undefined, options).resolvedOptions().timeZone;
      
      // Set the timezone in localStorage
      localStorage.setItem('timezone', currentTimeZone);
         return {
            ...state,
            isLoggedIn: true,
            user: action.payload
         }
      case VERIFY_CODE:
         return {
            ...state,
            isVerify: true,
         }
      case FORGET_PASSWORD:
         return {
            ...state,
            user: action.payload,
         }
      case VERIFY:
         return {
            ...state,
            user: action.payload,
         }
      case RESET_PASSWORD:
         return {
            ...state,
            isVerify: true,
         }
      case TOGGLE_STATE:
         return {
            ...state,
            registered: false,
            error: false
         }
      case ERROR_STATE:
         return {
            ...state,
            error: true
         }
      default:
         return state
   }
}

export default authReducer
