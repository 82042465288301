import React from 'react'
import Sidebar from './Sidebar/Sidebar';
import DashboardStates from './DashboardStates';
import './dashboard.scss'

function Dashboard() {
    const activePage = 'dashboard'
    return (
        <div>
            <Sidebar activePage={activePage} />
            <div className='dashboard-wraper'>
                <DashboardStates />
            </div>
        </div>
    )
}

export default Dashboard
