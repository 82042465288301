import { GET_SETTINGS, ERROR_STATE } from './settingsTypes';

const initialState = {
   data: null,
   success: false,
   error: false,
}

const settingsReducer = (state = initialState, action) => {
   switch (action.type) {
      case GET_SETTINGS:
         return {
            ...state,
            data: action.payload,
            success: true
         }
      case ERROR_STATE:
         return {
            ...state,
            error: true
         }
      default:
         return state
   }
}

export default settingsReducer
