import React, { useEffect } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sliderCoursesListing } from '../../../redux/course/courseActions';

function Aljadid() {
    const dispatch = useDispatch();
    const coursesData = useSelector(state => state.course.sliderCourses);

    useEffect(() => {
        dispatch(sliderCoursesListing());
        window.scroll(0, 0)
    }, []);

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "190px",
        slidesToShow: 3,
        arrows: true,
        speed: 500,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    centerPadding: "130px",
                },

            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    centerPadding: "0px",
                },

            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    centerPadding: "0px",
                    infinite: true,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "0px",
                    infinite: true,
                }
            }
        ]
    };


    return (
        <div>
            <div className='Aljadid__content 2xl:mt-20 md:mt-10' >
                <div className='aljadid_conent text-center 2xl:mb-16 md:mb-12'>
                    <h2 className="text-4xl font-bold dark_color mb-6">ماهو الجديد في موقع م. نبيلة</h2>
                    {/* <p className="f-size-26 yellow-clr pt-4">قائمة كبيرة من جهد نبيلة لك</p> */}
                </div>
                <div className='aljadid__video'>
                    {coursesData?.courses?.length > 3 ?
                        <div>
                            <Slider {...settings}>
                                {coursesData?.courses?.slice(0, 4)?.map((course, index) => {
                                    const imageUrl = process.env.REACT_APP_FILE_URL + course?.thumbnailpic;
                                    const divStyle = {
                                        backgroundImage: `url(${imageUrl})`,
                                        backgroundRepeat: 'no-repeat'
                                    };
                                    return (
                                        <div key={index}>
                                            <div className='jadid-videolame relative '>
                                                <div className="tabs_conten_wraper">
                                                    <div className="coleague-wrap coleagesecond p-4 aljadid_card_section" style={divStyle}>
                                                        <div className="relative z-10 w-full text-center" >
                                                            <h5 className="text-white f-size-26 ">{course?.title}</h5>
                                                            <div className="  mt-3 mb-5">
                                                                <Link to={`/course/${course._id}`} className="theme-btn  ">استكشف الدورة</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                        :
                        <div className='theme__container'>
                            <div className='flex w-full'>
                                <div class="grid grid-cols-1 sm:grid-cols-3 2xl:grid-cols-3 gap-4 w-full">
                                    {coursesData?.courses?.slice(0, 4)?.map((course, index) => {
                                        const imageUrl = process.env.REACT_APP_FILE_URL + course?.thumbnailpic;
                                        const divStyle = {
                                            backgroundImage: `url(${imageUrl})`,
                                            backgroundRepeat: 'no-repeat'
                                        };
                                        return (
                                            <div className='content_wraper'>
                                                <div key={index} className='w-full' >
                                                    <div className='jadid-videolame relative '>
                                                        <div className="coleague-wrap coleagesecond p-4 aljadid_card_section" style={divStyle}>
                                                            <div className="relative z-10 w-full text-center" >
                                                                <h5 className="text-white f-size-26 ">{course?.title}</h5>
                                                                <div className="  mt-3 mb-5">
                                                                    <Link to={`/course/${course._id}`} className="theme-btn  ">استكشف الدورة</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Aljadid;
