import React from 'react'
import "./CourseDetails.scss"

function CourseBanner({ bannerPic }) {

    const imageUrl = process.env.REACT_APP_FILE_URL + bannerPic;
    const divStyle = {
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat'
    };

    return (
        <div>
            <section className="cart_banner" style={divStyle}>
                <div className="theme__container w-full">
                    <div className="cart_banner-content">
                        <div className="m-auto flex flex-col justify-center items-center">
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CourseBanner
