import React, { useEffect, useState } from 'react'
import "./Articals.css";
import '../../../../src/assets/css/cmsbanner.css';
import Faqat from '../../common/Faqat/Faqat';
import Shahdat from '../../common/Shahadat/Shahdat';
import { Link } from 'react-router-dom';
import { adminApiHelper } from '../../../redux/adminApiHelper';
import Loader from '../../../config/Loader';

function Articles() {

    const [articles, setArticles] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showArticles, setShowArticles] = useState(false);
    useEffect(() => {
        async function FetchData() {
            setIsLoading(true)
            await adminApiHelper("get", `/articles/?status=${true}`).then((result) => {
                setArticles(result?.data?.articles)
                setIsLoading(false)
            }).catch((err) => {
                console.log(err, "error")
                setIsLoading(false)
            });
        }
        FetchData();
        window.scroll(0, 1)
    }, [])

    return (
        <div>
            <div className="cms-pages-baner artical-baner">
                <div className="theme__container">
                    <div className='cms-content-heading'>
                        <h2 className='f-size-180 2xl:text-9xl lg:text-7xl md:text-5xl text-3xl text-white bold '>
                        المقالات
                        </h2>
                        <p className='2xl:text-5xl xl:text-4xl md:text-3xl text-2xl font-light text-white'>
                        مقالاتي هي مساحتي الخاصة لتدوين إلهاماتي وأفكاري م. نبيلة
                        </p>
                    </div>
                </div>
            </div>
            <section className='articals-list'>
                <div className="theme__container">
                    <div className="grid cmd:grid-cols-3 xm:grid-cols-2 gap-4 xl:my-20 lg:my-10 my-8 relative">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <> {articles?.length ? (<>
                                {articles?.slice(0, showArticles ? articles?.length : 6)?.map((article, index) => {
                                const imageUrl = process.env.REACT_APP_FILE_URL + article?.image;
                                const divStyle = {
                                    backgroundImage: `url(${imageUrl})`,
                                    backgroundRepeat: 'no-repeat'
                                };
                                return (
                                    <div key={index} className="artical-box-wraper" style={divStyle}>
                                        <img></img>
                                        <div className="artical-info p-6">
                                            <div className="relative w-100">
                                                <h5 className="text-white f-size-26 text-center">{article?.title}</h5>
                                            </div>
                                            <div className='hover--layout'>
                                                <Link to={`/articles/${article?._id}`} className=" mt-3 mb-5">
                                                    <div className=" theme-btn ">أكمل القراءة</div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}{articles?.length > 6 && !showArticles && (
                                <div className='no_course_fd_btn'>
                                <button className="theme-btn " onClick={() => setShowArticles(true)}>
                                    Load More
                                </button>
                            </div>
                              )}
                              </>) : <h1 className='orange-color no_course_fd'> لم يتم العثور على مقال </h1>}  </>)}
                    </div>
                </div>
            </section>
            <Faqat />
            <Shahdat />
        </div>
    )
}

export default Articles
