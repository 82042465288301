import React, { useEffect } from 'react'
import SiteLoader from "../../../site/src/assets/images/Spinner-1s-304px.gif"

const Loader = () => {

 

   return (
      <div>
         <div className='main-panel-ste site__content'>
            <div className='fullpage-loader-holder '>
               <div className='site__loader'>
                  <img src={SiteLoader} alt='loader' />
               </div>
            </div>
         </div>
      </div>
   )
}

export default Loader
