const Columns = () => {
   const columns = [
      {
         name: 'تاريخ الدفع',
         selector: row => row?.status,
         sortable: true,
      },
      // {
      //    name: 'كمية',
      //    selector: row => `$ ${row?.amount}`,
      //    sortable: true,
      // },
      {
         name: 'التفاصيل',
         selector: row => row?.description,
         sortable: true,
      },
      {
         name: 'المجموع',
         selector: row => row?.type,
         sortable: true,
      },
      // {
      //    name: 'تاريخ',
      //    selector: row => {
      //       const date = new Date(row?.createdAt);
      //       const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      //       return date.toLocaleDateString(undefined, options).replace(/\//g, '-');
      //    },
      //    sortable: true,
      // },
   ];

   return columns;
};

export default Columns;
