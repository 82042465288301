import React from 'react'
import Paymentbaner from './PaymentCallbackBnner'

function PaymentCallback() {
  return (
    <div>
      <Paymentbaner />
    </div>
  )
}

export default PaymentCallback
