import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function CourseVideo() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const file = queryParams.get("file");
  const originalName = queryParams.get("originalName");
  const itemId = queryParams.get("id");
  const coursetitle = queryParams.get("coursetitle");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="main_watch_video_wrapper">
      <div className="main_watch_video_header">
        <h2 className="2xl:text-4xl font-bold md:text-3xl kaiftext-sm dark_color">
          <Link to={`/course/${itemId}`}>{coursetitle}</Link>
        </h2>
        <span class="yellow-clr text-[22px]">{originalName}</span>
      </div>
      <div className="courses_detail_video mx-3 product-detail 2xl:my-8 xl:my-6 lg:my-5 my-4 watch_video">
        <video
          width="700"
          className="mx-auto p-0"
          height="500"
          src={process.env.REACT_APP_FILE_URL + file}
          controls
        ></video>
      </div>
    </div>
  );
}

export default CourseVideo;
