import React, { useEffect, useState } from 'react'
import './Consultation.scss'
import socialscreen from "../../../assets/images/socialscreen.png"
import clock from "../../../assets/images/24clock.png"
import chat from "../../../assets/images/chat.png"
import Spinner from "../../../assets/images/Spinner.gif";
import Shahadat from "../../common/Shahadat/Shahdat"
import Select from 'react-select';
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import { apiHelper } from '../../../redux/apiHelper'
import { adminApiHelper } from '../../../redux/adminApiHelper'
import { useDispatch, useSelector } from 'react-redux'
import { getSettings } from '../../../redux/settings/settingsAction'
import { useNavigate } from 'react-router-dom'

function Consultation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings.data)
    const userId = JSON.parse(localStorage.getItem('userId'));
    const [country, setCountry] = useState();
    const [countryName, setCountryName] = useState();
    const [availability, setAvailability] = useState();
    const [day, setDay] = useState()
    const currentDate = new Date().toISOString().split('T')[0];
    const [isLoading, setIsLoading] = useState(false);
    const [slots, setSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState('')
    const [type, setType] = useState();
    const [selectedType, setSelectedType] = useState('');
    const [question, setQestion] = useState([]);
    let token = localStorage.getItem('token')

    const schema = yup.object().shape({
        firstName: yup
            .string()
            .required('الإسم الأول مطلوب')
            .min(3, 'قصير جدا')
            .max(30, 'طويل جدا'),
        lastName: yup
            .string()
            .required('إسم العائلة مطلوب')
            .min(3, 'قصير جدا')
            .max(30, 'طويل جدا'),
        email: yup.string().required('البريد الالكتروني مطلوب').matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'تنسيق بريد إلكتروني غير صالح'),
        phone: yup.string()
            .required('رقم الهاتف مطلوب')
            .matches(/^[0-9]+$/, 'يجب أن يحتوي رقم الهاتف على أرقام فقط')
            .min(11, 'يجب ألا يقل رقم الهاتف عن 11 رقمًا')
            .max(16, 'يمكن أن يتكون رقم الهاتف من 16 رقمًا على الأكثر')
            .nullable(true),
        age: yup.number().typeError('يجب أن يكون العمر رقمًا')
            .positive('يجب أن يكون العمر موجبًا')
            .integer('يجب أن يكون العمر عددًا صحيحًا')
            .required('العمر مطلوب')
            .nullable(),
        date: yup.date().min(currentDate, 'لا يمكن تحديد تاريخ سابق').required('التاريخ مطلوب'),
        // questions: yup.string().required('مطلوب سؤال'),
        description: yup.string().required('الوصف مطلوب'),
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    })
    async function FetchType() {
        try {
            const response = await adminApiHelper('get', `/consultation-questions?pagination=${'false'}`);
            const data = response?.data?.consultationQuestions || [];
            setType(data);
            // Extracting unique types from the data array
            const uniqueTypes = [...new Set(data.map(item => item.type))];
        } catch (error) {
            // Handle the error here
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        setIsLoading(true)
        try {
            dispatch(getSettings())
        } catch (error) {
            console.log(error)
        }
        async function FetchCountryData() {
            await apiHelper("get", `/countries`)
                .then((data) => {
                    setCountry(data?.data?.countries);
                    setIsLoading(false)
                });
        }
        FetchCountryData();
        FetchType()
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [])

    // Filtered options containing only unique types
    const filteredOptions = type?.filter((item, index, self) => self.findIndex(i => i.type === item.type) === index)
        .map(item => ({ value: item.type, label: item.type }));

    const handleSelectType = (selectedOption) => {
        setSelectedType(selectedOption.value);
        async function FetchData() {
             await adminApiHelper('get', `/consultation-questions?type=${selectedOption.value}&pagination=${'false'}`).then((result) => {
                console.log(result, "resultresult");
                const data = result?.data?.consultationQuestions || [];
                setQestion(data);
                if (!result?.data?.consultationQuestions?.length) {
                    toast.error("No question found")
                }
            }).catch((err) => {
                console.log("error", err)
            });
        }
        FetchData()
    };

    // Handle country
    const handleSelectChange = (name, selectedOption) => {
        setCountryName(selectedOption ? selectedOption.value : "");
    };
    const handleDateChange = (event) => {
        const date = new Date(event);
        const options = { weekday: 'long' };
        const dayName = date.toLocaleDateString('en-US', options);
        setDay(dayName)
        const dateSelect = moment(event).format('YYYY-MM-DD');
        async function FetchData() {
            await apiHelper("get", `/availability?day=${dayName}&date=${dateSelect}`).then((result) => {
                setAvailability(result?.data?.availability);
                if (!result?.data?.availability?.length) {
                    toast.error("Slot is not available")
                }
            }).catch((err) => {
                console.log("error", err)
            });
        }
        FetchData()
    }

    const handleSelectSlot = (selectedOption) => {
        const selectedSlot = selectedOption ? selectedOption.value : '';
        setSelectedSlot(selectedSlot)
    }
    const handleSelectQuestion = (selectedOption) => {
        const selectedQuest = selectedOption ? selectedOption.value : '';
        setSelectedQuestion(selectedQuest)
    }

    const handleFormSubmit = async (data) => {
        const { firstName, lastName, ...rest } = data;
        const name = `${firstName} ${lastName}`;
        const date = moment(data.date).format('YYYY-MM-DD');
        const questions = selectedQuestion;
        const type = selectedType;
        const newData = { ...rest, name, countryName, date, selectedSlot,type, questions, day, userId };
        const response = await adminApiHelper("post", "/consultations/", newData)
        if (response?.data.success) {
            toast.success(response?.data?.message)
            reset();
            setSelectedSlot('');
            setDay('');
            setSelectedQuestion('');
            setSelectedType('');
            setSlots([]);
            setQestion([]);
            setCountryName('')
        } else {
            toast.error(response?.data?.message)
        }
    }
    function formatTime(time) {
        const [hours, minutes] = time.split(':');
        let formattedTime = `${parseInt(hours, 10) % 12}:${minutes}`;
        formattedTime += parseInt(hours, 10) >= 12 ? ' PM' : ' AM';
        return formattedTime;
    }
    const customStyles = {
        option: (provided) => ({
          ...provided,
          fontSize: '26px', // Adjust the font size as needed
        }),
      };

    return (
        <div className='consultation_wrapper'>

            <div className="cms-pages-baner consultation-baner">
                <div className="theme__container">
                    <div className='cms-content-heading'>
                        <h2 className='f-size-180 2xl:text-9xl lg:text-7xl md:text-5xl text-3xl  text-white bold  xl:mb-5 mb-3'>
                        الاستشارات
                        </h2>
                        <p className='2xl:text-5xl xl:text-4xl md:text-3xl text-2xl font-light text-white'>
                        تواصل معي للحصول على استشارة شخصية مصممة خصيصاً لك
                        </p>
                    </div>
                </div>
            </div>

            <section className='2xl:py-16 lg:py-12 py-6'>
                <div className='theme__container'>
                    <h2 className="f-size-70 font-light dark_color dark_shadow text-center ">استشارة مميزة، وعي مختلف، نقلات كبيرة</h2>
                    <p className='f-size-27 dark_color dark_shadow consult-text pb-20 '>تعال لهذه الاستشارة وأنت محددة الأشياء التي تريد أن تغيريها وتعمل نقلات فيها لننطلق معاً في رحلة نورانية مليئة بالحُب لنكتشف ما يُمكن تغييره في عالمكِ! 
لدعم أكثر وأعمق ستزوّدكِ نبيلة خلال الإستشارة بكل ما يتناسب معك من تأملات أو جُمل أو آيات تتفكر فيها . كل ما عليكِ هو أن تأتي للإستشارة وأنت مسترخي وجاهزة للتغيير !</p>
                    <div className='social_screen relative'>
                        <img src={socialscreen} alt="social" className='m-auto' />
                        <div className='clock_box absolute offset-right'>
                            <span className='flex items-center pb-4'>
                                <img src={clock} alt="24clock.png" />
                                <p className='pr-6 orange-color f-size-27  dark_shadow'>متاح 24/7</p>
                            </span>
                            <p className='f-size-14 dark_color font-light dark_shadow '>يمكنك حجز الإستشارة المناسبة في الوقت المناسب لك!</p>
                        </div>
                        <div className='clock_box absolute offset-left'>
                            <span className='flex pb-4'>
                                <img src={chat} alt="24clock.png" />
                                <p className='pr-6 orange-color f-size-27  dark_shadow'>م. نبيلة</p>
                            </span>
                            <p className='f-size-14 dark_color font-light dark_shadow '>احصل على استشارة أونلاين مصممة لك خصيصاً مع مراعاة الخصوصية التامة</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="contact" className='contactus--form 2xl:py-28 md:py-14 py-8 cart--items-sec '>
                <div className="theme__container">
                
                    <div className='sm:grid cmd:grid-cols-2 common-card-style'>
                        <div className='contact-right-form'>
                            <div className='flex'>
                                <h6 className='dark_color 2xl:text-4xl xl:text-3xl lg:text-xl md:text-lg text-3xl  font-bold'>
                                تفاصيل حجز الاستشارة
                                </h6>
                            </div>
                            <form onSubmit={handleSubmit(handleFormSubmit)}
                                className="form-horizontal"
                            >
                                <div className="mb-5 sm:grid cmd:grid-cols-2 gap-5">
                                    <div className='flex flex-col'>
                                        <label className='d-block lable-style'>الاسم الأول<sup className="staric">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lname"
                                            placeholder=""
                                            {...register("firstName")}
                                        />
                                        <span className='color-red'>{errors.firstName?.message}</span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <label className='d-block lable-style'>اسم العائلة<sup className="staric">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lname"
                                            placeholder=""
                                            {...register("lastName")}
                                        />
                                        <span className='color-red'>{errors.lastName?.message}</span>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className='d-block lable-style'>البلد / المنطقة<sup className="staric">*</sup></label>
                                    <Select
                                        onChange={(selectedOption) => handleSelectChange("countryName", selectedOption)}
                                        options={country && country?.map((countryCode) => ({ value: countryCode.name, label: countryCode.name }))}
                                        classNamePrefix='common-select-style'
                                        styles={customStyles}
                                        name="countryName"
                                        value={countryName ? { value: countryName, label: countryName } : null}
                                        required
                                    />
                                </div>
                                <div className="mb-5">
                                    <label className='d-block lable-style'>البريد الإلكتروني<sup className="staric">*</sup></label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="lname"
                                        placeholder=""
                                        name="email"
                                        {...register("email")}
                                    />
                                    <span className='color-red'>{errors.email?.message}</span>
                                </div>
                                <div className="mb-5">
                                    <label className='d-block lable-style'>العمر<sup className="staric">*</sup></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="lname"
                                        placeholder=""
                                        name="age"
                                        {...register("age")}
                                    />
                                    <span className='color-red'>{errors?.age?.message}</span>
                                </div>
                                <div className="mb-5">
                                    <label className='d-block lable-style'>رقم الهاتف<sup className="staric">*</sup></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="lname"
                                        placeholder=""
                                        name="phone"
                                        {...register("phone")}
                                    />
                                    <span className='color-red'>{errors.phone?.message}</span>
                                </div>
                                <div className="mb-5">
                                    <label className='d-block lable-style'>اختر التاريخ المناسب<sup className="staric">*</sup></label>
                                    <input
                                        onInput={(event) => handleDateChange(event.target.value)}
                                        type="date"
                                        className="form-control"
                                        id="lname"
                                        placeholder=""
                                        name="date"
                                        {...register("date")}
                                    />
                                    <span className='color-red'>{errors.date?.message}</span>
                                </div>
                                <div className="mb-5">
                                    <label className='d-block lable-style'>الأوقات المتاحة<sup className="staric">*</sup></label>
                                    <Select
                                        onChange={handleSelectSlot}
                                        options={availability?.map(slot => ({
                                            value: `${slot?.startTime} - ${slot?.endTime}`,
                                            label: `${formatTime(slot?.startTime)} - ${formatTime(slot?.endTime)}`
                                        }))}
                                        classNamePrefix='common-select-style'
                                        styles={customStyles}
                                        value={selectedSlot ? { value: selectedSlot, label: selectedSlot } : null}
                                        required
                                    />
                                </div>
                                <div className="mb-5">
                                    <label className='d-block lable-style'>نوع الاستشارة<sup className="staric">*</sup></label>
                                    <Select
                                        onChange={handleSelectType}
                                        options={filteredOptions}
                                        classNamePrefix="common-select-style"
                                          styles={customStyles}
                                        value={selectedType ? { value: selectedType, label: selectedType } : null}
                                        required
                                    />
                                </div>
                                <div className="mb-5">
                                    <label className='d-block lable-style'>اجب عن الأسئلة التالية<sup className="staric">*</sup></label>
                                    <Select
                                        onChange={handleSelectQuestion}
                                        options={question?.map(quest => ({
                                            value: quest?.question,
                                            label: quest?.question
                                        }))}
                                        classNamePrefix='common-select-style'
                                        styles={customStyles}
                                        value={selectedQuestion ? { value: selectedQuestion, label: selectedQuestion } : null}
                                        required
                                    />
                                   
                                </div>
                                <div className='flex flex-col'>
                                    <label className='d-block lable-style'>ملاحظات الطلب<sup className="staric">*</sup></label>
                                    <textarea
                                        className="form-control"
                                        rows="5"
                                        placeholder=""
                                        name="description"
                                        {...register("description")}
                                    >
                                    </textarea>
                                    <span className='color-red'>{errors.description?.message}</span>
                                </div>
                                {token ? <div className=" mt-10">
                                    <button className="theme-btn" type='submit'>احجز الآن</button>
                                </div>
                                :
                                <div className=" mt-10">
                                    <button className="theme-btn" onClick={() => navigate('/login')}>تسجيل الدخول</button>
                                </div>
                                }
                            </form>
                        </div>
                        <div className='contact--info consult__form flex justify-center flex-col'>
                            <h3 className='2xl:text-6xl xl:text-2xl lg:text-xl md:text-lg text-3xl font-bold text-start'>فوائد الانضمام إلينا</h3>
                            <p className='f-size-27 font-light dark_color consult-max-wd pt-10'>
                            عالم م. نبيلة يتميز بالمرونة وروح البهجة والحُب فأنت ستشعر بذلك من أول لحظة !
                           <br/>
                            عالم يُسهّل عليك استكشاف وجهات نظر جديدة، و التخلص من العادات والسلوكيات القديمة، واحتضان قوة التغيير في حياتك . 
                            </p>
                            <div className='mt-10'>
                                <ul>
                                    <li className="flex items-center mb-4">
                                        <span className="circle-block f-size-26 orange-color ml-4">1</span>
                                        <p className="dark_color lg:text-xl listing_f-sze font-light">عالم يجعلك تؤمن بقوة التشافي الذي وضعهُ الله بداخلك .</p>
                                    </li>
                                    <li className="flex items-center mb-4">
                                        <span className="circle-block f-size-26 orange-color ml-4">2</span>
                                        <p className="dark_color lg:text-xl listing_f-sze font-light">عالم يساهم لك بكل يُسر في تغيير نظرتك لنفسك وإيجاد الطُرق النورانية التي تُقربّك من خالقك .</p>
                                    </li>
                                    <li className="flex items-center mb-4">
                                        <span className="circle-block f-size-26 orange-color ml-4">3</span>
                                        <p className="dark_color lg:text-xl listing_f-sze font-light">عالم يساعدك في اكتشاف قوة قلبك والعلم الذي وضعهُ الله فيك وتفعيل سمعك وبصرك .</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* </>
                    } */}
                </div>
            </section>
            <Shahadat />
        </div>

    )
}

export default Consultation
