import React from 'react'
import CmsPagesbaner from './PrivacyBnner'
import CmsContent from './PrivacyContent'

function PrivacyPolicy() {
  return (
    <div>
      <CmsPagesbaner />
      <CmsContent />
    </div>
  )
}

export default PrivacyPolicy
