import React, { useEffect } from 'react'

function CmsPagesbaner() {

  useEffect(() => {
    window.scrollTo(0, 1)
  }, [])

  return (
    <div>
      <div className='cms-wraper'>
        <div className="cms-pages-baner chechout_banner terms_banner">
          <div className="theme__container">
            <div className=''>
              <h2 className='2xl:text-8xl xl:text-7xl lg:text-5xl text-3xl  dark_color bold mb-5 text-center'>
                سياسة الخصوصية
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CmsPagesbaner
