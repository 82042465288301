import React, { useEffect, useState } from 'react'
import "./Cart.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../config/Loader';

function Cart() {

    const { search } = useLocation();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true)
        const storedItems = JSON.parse(localStorage.getItem('items')) || [];
        setItems(storedItems);  
        setIsLoading(false)
    }, []);

    const calculatePrice = () => {
        let totalPrice = 0;
        let discountedPrice;
        let totalDiscountedPrice = 0;
        items.forEach((item) => {
            totalPrice += item.price;
            if (item?.discount && new Date().toISOString().split("T")[0] >= item?.discount?.startDate.split("T")[0] && new Date().toISOString().split("T")[0] <= item?.discount?.endDate.split("T")[0]) {
                discountedPrice = (item.price * item?.discount?.value) / 100;
                totalDiscountedPrice += discountedPrice;
            }
        });
        return {
            totalPrice: totalPrice.toFixed(2),
            totalDiscountedPrice: totalDiscountedPrice.toFixed(2),
            grandTotal: (totalPrice - totalDiscountedPrice).toFixed(2),
        };
    };
    const { totalPrice, totalDiscountedPrice, grandTotal } = calculatePrice();

    const handleDeleteItem = (item) => {
        const existingItems = JSON.parse(localStorage.getItem("items"));
        const updatedItems = existingItems.filter((existingItem) => existingItem._id !== item._id);
        localStorage.setItem("items", JSON.stringify(updatedItems));
        window.dispatchEvent(new Event("storage"));
        setItems(updatedItems);
    };

    return (
            <div className='chechout_banner_wrapper'>
                <div className="cms-pages-baner  chechout_banner">
                    <div className="theme__container">
                        <div className='cms-content-heading'>
                            <h2 className='2xl:text-9xl xl:text-7xl lg:text-5xl text-3xl  dark_color bold  text-center'>
                            نضمن لك عملية دفع آمنة
                            </h2>
                            <p className='2xl:text-5xl xl:text-3xl md:text-3xl text-2xl font-light dark_color text-center '>
                            اتمم عملية الشراء بكل سهولة ويسر وفي أسرع وقت
                            </p>
                        </div>
                    </div>
                </div>
                {isLoading ?(
                    <Loader />
                ):
                    (<section id="contact" className='contactus--form 2xl:py-28 md:py-14 py-8 cart--pg cart--items-sec '>
                        <div className="theme__container">
                            <div className='sm:grid cmd:grid-cols-2 common-card-style form--wraper'>
                                <div className='contact--info'>
                                    <h2 className='checkout-heading text-white 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl text-3xl font-bold text-center '>
                                    تفاصيل المشتريات
                                    </h2>
                                    {items.length === 0 ? (
                                        <p className="text-center text-2xl text-gray-500 mt-10 mb-10">عربة التسوق فارغة</p>
                                    ) : (
                                        <>
                                            <div className='flex items-center justify-between padding-50'>
                                                <h3 className='2xl:text-3xl md:text-xl dark_color font-medium dark_shadow'>الدورات</h3>
                                                <h3 className='2xl:text-3xl md:text-xl dark_color font-medium dark_shadow'>السعر</h3>
                                                <h3 className='2xl:text-3xl md:text-xl dark_color font-medium dark_shadow'>تحديث</h3>
                                            </div>
                                            {items?.map((item, index) => {
                                                return (
                                                    <div className='price_listing flex items-center justify-between' key={index}>
                                                        <span className='dark_color font-light dark_shadow f-size-27'>{item?.name}</span>
                                                        <span className='dark_color font-light dark_shadow f-size-27'>
                                                            <span className='lato'>{item.price}</span>$
                                                        </span>
                                                        <button className="theme-btn" onClick={() => handleDeleteItem(item)}>حذف</button>
                                                    </div>
                                                )
                                            })}
                                            <hr />
                                            <div className='price_listing flex items-center justify-between'>
                                                <span className='dark_color font-medium dark_shadow f-size-27'>السعر الإجمالي</span>
                                                <span className='dark_color font-light dark_shadow f-size-27'>
                                                    <span className='lato'>{totalPrice}</span>$
                                                </span>
                                            </div>
                                            <div className='price_listing flex items-center justify-between'>
                                                <span className='dark_color font-light dark_shadow f-size-27'>سعر التخفيض</span>
                                                <span className='dark_color font-light dark_shadow f-size-27'>
                                                    <span className='lato'>{totalDiscountedPrice}</span>$
                                                </span>
                                            </div>
                                            <div className='price_listing flex items-center justify-between bg-white'>
                                                <span className='dark_color font-medium dark_shadow f-size-27'>السعر النهائي</span>
                                                <span className='dark_color font-light dark_shadow f-size-27'>
                                                    <span className='lato'>{grandTotal}</span>$
                                                </span>
                                            </div>
                                            <div className='banking-card 2xl:mt-6 sm:mt-1 2xl:mb-10 sm:mb-2'>
                                                <div className=" text-center md:mt-8 mt-5 m-auto check_out-btn">
                                                    <button className="theme-btn" onClick={() => { navigate('/checkout?type=course') }}>إتمام الشراء</button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>)
                }
            </div>
    )
}

export default Cart
