import { ERROR_STATE, GET_SETTINGS } from "./settingsTypes";
import { toast } from "react-toastify";
import { adminApiHelper } from "../adminApiHelper"

export const getSettings = () => async (dispatch) => {
  try {
    let res = await adminApiHelper("get", `/settings`)
    if (res?.data) {
      let { data } = res
      dispatch({
        type: GET_SETTINGS,
        payload: data
      })
    }
    else {
      dispatch({
        type: ERROR_STATE
      })
    }
  } catch (error) {
    toast.error(error.response?.data?.message)
  }
}
