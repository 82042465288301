import React from "react";
import moment from "moment";

const Columns = () => {
  var typesArr = {
    "monthly": "شهر واحد",
    "quarterly": "ثلاثة أشهر",
    "half-yearly": "ستة أشهر",
    "yearly": "سنة واحدة",
  }

  const columns = [
    {
      name: "نوع الاشتراك",
      selector: (row) => row?.plan?.name,
      sortable: true,
    },
    {
      name: "تفاصيل الاشتراك",
      selector: (row) => typesArr[row?.plan?.type],
      sortable: true,
    },
    {
      name: "سعر الاشتراك",
      selector: (row) => row?.plan?.price,
      sortable: true,
    },
    {
      name: "تاريخ بدء الاشتراك",
      selector: (row) => {
        const timestamp = row?.startDate;
        if (timestamp) {
          const utcDate = moment.unix(timestamp).utc().format("YYYY-MM-DD");
          const timeArr = moment.unix(timestamp).utc().format("hh:mm A");
          return `${utcDate} ${timeArr}`;
        }
        return null;
      },
      sortable: true,
    },
    {
      name: "تاريخ انتهاء الاشتراك",
      selector: (row) => {
        const timestamp = row?.endDate;
        if (timestamp) {
          const utcDate = moment.unix(timestamp).utc().format("YYYY-MM-DD");
          const timeArr = moment.unix(timestamp).utc().format("hh:mm A");
          return `${utcDate} ${timeArr}`;
        }
        return null;
      },
      sortable: true,
    },
    {
      name: "حالة الاشتراك",
      selector: (row) => {
        if (row?.status) {
          return "نشيط";
        } else {
          const currentDate = new Date();
          const startDate = new Date(row?.startDate * 1000);
          const endDate = new Date(row?.endDate * 1000);
          if (currentDate >= startDate && currentDate <= endDate) {
            return "ألغيت";
          } else {
            return "منتهي الصلاحية";
          }
        }
      },
      sortable: true,
    },
  ];

  return columns;
};

export default Columns;
