import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Columns = () => {
  const months = [
    { en: 'January', ar: 'يناير' },
    { en: 'February', ar: 'فبراير' },
    { en: 'March', ar: 'مارس' },
    { en: 'April', ar: 'أبريل' },
    { en: 'May', ar: 'مايو' },
    { en: 'June', ar: 'يونيو' },
    { en: 'July', ar: 'يوليو' },
    { en: 'August', ar: 'أغسطس' },
    { en: 'September', ar: 'سبتمبر' },
    { en: 'October', ar: 'أكتوبر' },
    { en: 'November', ar: 'نوفمبر' },
    { en: 'December', ar: 'ديسمبر' }
  ];
  const days = ["اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت", "اﻷحد"];
  const format = { PM: "مساءً", AM: "أكون" };

  const columns = [
    {
      name: 'عنوان اللقاء',
      selector: row => row?.name,
      sortable: true,
    },
    {
      name: 'سعر',
      selector: row => {
        const price = row?.price;
        return price ? `$ ${price}` : '$ 0';
      },
      sortable: true,
    },
    {
      name: 'الفئة',
      selector: row => row?.type,
      sortable: true,
    },
    {
      name: 'تاريخ اللقاء',
      selector: row => {
        const eventDate = new Date(row?.date);
        const eventMonthIndex = eventDate.getMonth();
        const eventMonth = months[eventMonthIndex]?.ar;
        const customDate = moment(eventDate).format('YYYY-MM-DD');
        const timeArr = moment(`${customDate} ${row?.time}`).format('hh:mm A').split(' ');
        const time = timeArr[0];
        const timeFormat = format[timeArr[1]];
        return (
          <div>
            <span>{eventDate.getDate()} {eventMonth}</span> <span>{`${time} ${timeFormat}`}</span> {days[eventDate?.getDay() - 1]}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: 'رابط اللقاء',
      selector: (row) => (
        <div className='views_btn_wraper'>
          <button disabled={new Date() <= new Date(row?.date) ? false : true} className="theme-btn theme_views_btn" onClick={() => window.open(row?.zoomLink, '_blank')}>
            ينضم
          </button>
        </div>
      ),
    },
  ];

  return columns;
};

export default Columns;
