import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { adminApiHelper } from "../../../redux/adminApiHelper";
import TestimonialContent from "./TestimonialContent";
import TestimonialContentGrid from "./TestimonialContentGrid";
import { ReactComponent as Star } from "../../../assets/images/star-icon.svg";
import { ReactComponent as StarIcon } from "../../../assets/images/star-icon.svg";
function Shahdat() {
  const [testimonial, setTestimonial] = useState();
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    arrows: true,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "2px",
          infinite: true,
        },
      },
    ],
  };

  useEffect(() => {
    adminApiHelper("get", `/testimonial/?status=${true}`)
      .then((result) => {
        setTestimonial(result?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div className="shahdat-wrapper 2xl:my-16 md:my-10">
        <div className="shahat-text text-center pb-12 ">
          <h2>تقييم عملائنا</h2>
          {/* <h5 className="light-yellow f-size-26 font-light pb-12">
            كلام العميل يتحدث عن خدمات نبيلة عباس
          </h5> */}
        </div>
        <div>
          {testimonial?.length > 3 ? (
            <Slider {...settings}>
              {/* <TestimonialContent testimonial={testimonial} />
               */}
              {testimonial?.length
                ? testimonial?.map((testimonial, index) => {
                    const rating = testimonial?.rating || 0;
                    const starIcons = Array.from({ length: 5 }, (_, i) => (
                      <div className="start_icon">
                        {i < rating ? (
                          <Star />
                        ) : (
                          <StarIcon className="blank_svg" />
                        )}
                      </div>
                    ));
                    return (
                      <div key={index}>
                        <div className="shahadat-slider p-6 text-center 2xl:pt-16 md:pt-16 ">
                          <div className="star-icon flex items-center justify-center pb-6">
                            {starIcons}
                          </div>
                          <h3 className="pb-24 font-light f-size-27">
                            {testimonial?.message}
                          </h3>
                          <h2 className="f-size-22">{testimonial?.name}</h2>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </Slider>
          ) : (
            <TestimonialContentGrid testimonial={testimonial} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Shahdat;
