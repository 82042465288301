import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import site_logo from "../../../assets/images/site_logo.png";
import Twiter from "../../../assets/images/twiter.svg";
import Insta from "../../../assets/images/insta.svg";
import Facebook from "../../../assets/images/facebook.svg";
import Youtube from "../../../assets/images/youtube.svg";
import Cart from "../../../assets/images/shopping_cart.png";
// import LanguageSelect from "../../../components/common/LanguageSelect/LanguageSelect";
import User from "../../../assets/images/User.svg";
import Search from "../../../assets/images/Search.svg";
import { adminApiHelper } from '../../../redux/adminApiHelper';

const Header = () => {

  const navigate = useNavigate(true)
  const location = useLocation();
  const [isActive, setActive] = useState(false);
  const [isSearchActive, setSearchActive] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');
  const [setting, setSetting] = useState();
  const [items, setItems] = useState([]);
  const inputRef = useRef();

  let token = localStorage.getItem('token')
  let loginUser = JSON.parse(localStorage.getItem('user'))

useEffect(() => {
    // Get items from localStorage on component mount
    const storedItems = JSON.parse(localStorage.getItem('items')) || [];
    setItems(storedItems);
  
    // Listen for storage event to update items
    window.addEventListener('storage', () => {
      const updatedItems = JSON.parse(localStorage.getItem('items'));
      setItems(updatedItems);
    });
}, []);

  const handleButtonClick = () => {
    setActive(!isActive);
    setSearchActive(false);
  };

  const handleButtonIcon = () => {
    setActive(false);
    setSearchActive(!isSearchActive);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSearchActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuClick = (menuItem) => {
    setActiveMenu(menuItem);
    setActive(false);
    setSearchActive(false);
  };

  const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('timezone');
    localStorage.removeItem('items');
    navigate('/');
  }

  useEffect(() => {
    async function FetchData() {
      await adminApiHelper("get", `/settings`).then((result) => {
        setSetting(result?.data);
      }).catch((err) => {
        console.log("error", err)
      });
    }
    FetchData();
  }, [])
  
  return (
    <div className="site__header relative">
      <div className="theme__container">
        <div className={`site__header__innertop flex pt-5 items-center justify-between`}>
          <div className='social__icons z-10'>
            <ul className='flex items-center'>
              <li><Link to={setting?.telegram}><img src={Twiter} alt="Telegram" /></Link></li>
              <li><Link to={setting?.instagram}><img src={Insta} alt="Instagram" /></Link></li>
              <li><Link to={setting?.facebook}><img src={Facebook} alt="Facebook" /></Link></li>
              <li><Link to={setting?.youtube}><img src={Youtube} alt="Youtube" /></Link></li>
            </ul>
          </div>
          <div className='site-logo desktop-block mobile-hide'>
            <Link to="/" className="site__logo pl-14">
              <img src={site_logo} alt="site_logo" />
            </Link>
          </div>
          <div className='flex items-center z-10'>
            {/* <LanguageSelect /> */}
            {token ?
              <>
                <button className="btn btn-primary mbl-f-size f-size-27" onClick={logOut}>تسجيل خروج
                </button> 
                <Link to="/dashboard" className='header-mobile-none header-desktop-block'>
                  <div className="user-cion flex items-center md:mx-8 2xl:mx-12">
                    <span>{loginUser.name}</span>
                    <div className="user-img mr-6">
                      <img src={User} alt='User' />
                    </div>
                  </div>
                </Link>
                
                <Link to='/cart' className='relative'> {items?.length > 0 ? <span className='absolute check_out_number'>{  items?.length }</span> :""}<img src={Cart} alt="Cart" width='30' /></Link>
              </> :
              <>
                <Link to="/login">
                  <div className="user-cion flex md:mx-8 2xl:mx-12">
                    <span>تسجيل الدخول</span>
                    <div className="user-img mr-6">
                      <img src={User} alt='User' />
                    </div>
                  </div>
                </Link>
              </>
            }
          </div>
        </div>
      </div>
      <div className='flex items-center mobile-bar'>
        <div className='desktop-none mobile-block'>
          <Link to="/" className="site__logo">
            <img src={site_logo} alt="site_logo" />
          </Link>
        </div>
        <div className="navbar_wrapper mr-auto self-center z-10">
          <div className={`hamburger-lines hidden mr-auto ${isActive ? "active" : ""}`} onClick={handleButtonClick}>
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
        </div>
        <div className={`site__header__inner ${isActive ? "active" : ""}`}>
          <nav className='main-navbar w-full py-5'>
            <ul className="flex justify-center">
              <li>
                <Link
                  to="/"
                  onClick={() => handleMenuClick('home')}
                  className={location.pathname === '/' ? 'active' : ''}
                >
                  الرئيسية
                </Link>
              </li>
              <li>
                <Link
                  to="/courses"
                  onClick={() => handleMenuClick('courses')}
                  className={location.pathname === '/courses' ? 'active' : ''}
                >
                  الدورات
                </Link>
              </li>
              <li>
                <Link
                  to="/events"
                  onClick={() => handleMenuClick('events')}
                  className={location.pathname === '/events' ? 'active' : ''}
                >
                 اللقاءات
                </Link>
              </li>
                <li>
                  <Link
                    to="/consultation"
                    onClick={() => handleMenuClick('consultation')}
                    className={location.pathname === '/consultation' ? 'active' : ''}
                  >
                    الاستشارات
                  </Link>
                </li>
                <li>
                  <Link
                    to="/plans"
                    onClick={() => handleMenuClick('plans')}
                    className={location.pathname === '/plans' ? 'active' : ''}
                  >
                   باقات الاشتراك
                  </Link>
                </li>
              <li>
                <Link
                  to="/articles"
                  onClick={() => handleMenuClick('articles')}
                  className={location.pathname === '/articles' ? 'active' : ''}
                >
                  المقالات
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  onClick={() => handleMenuClick('aboutus')}
                  className={location.pathname === '/aboutus' ? 'active' : ''}
                >
                  عالم م. نبيلة
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  onClick={() => handleMenuClick('contactus')}
                  className={location.pathname === '/contact-us' ? 'active' : ''}
                >
                  تواصل معنا
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
