import React, { useState } from "react";
import Mansat_Video from "../../../assets/video/Nabeela_v.mp4";
import mansatGirl from "../../../assets/images/mansat_girl.png";

import { Link } from "react-router-dom";

function Hayyat() {
 const [description, setDescription] = useState(false);
const toggleShortDescription = () => {
    setDescription(!description);
  };
  return (
    <div>
      <div className="hayyat_content ">
        <div className="theme__container">
          <div className="hayyat-wrapper flex items-start justify-between pt-28 md:pt-20 ">
            <div className="content-text">
              <div>
                <h2 className="2xl:text-4xl mb-4 md:text-3xl dark_color ">
                  <span className="orange-color f-size-52">
                    {" "}
                    من هي المهندسة نبيلة؟
                  </span>
                </h2>
              </div>
              <div className="hayyat-text font-size-27 font-light dark_color">
                {description === false ? (
                  <>
                    <p className="pb-5">
                      ماهو الشيء الذي جعلني اتجه من تخصص الهندسة المعمارية اللي
                      درسته وعملت فيه لفترة طويلة وكنت أحبه إلى التشافي؟ كنت احس
                      ان في شي أدور عليه خصوصا لما دخلت في مرحلة تعب نفسي وآلام
                      حادة في الظهر والرجلين .كانت الحياة كأنها واقفة وكل شي
                      واقف كل شي متعب وثقيل وكثير أيام كنت ما اقدر أكمل دوامي
                      ولازم ارجع البيت من كثر الألم .كل زاوية اطالعها ما كانت
                      حلوة مع اني انا شخصية جدا احب المرح والضحك والحركة بدأت
                      ادور على شيء بديل لأن أخذت جلسات علاج طبيعي كثير وجلسات
                      مساج وما كان ينفع مع آلام الظهر اللي عندي . ماهو الشيء
                      الذي ممكن أن يظهر لي وممكن أن يغير كل هذا جلست أبحث وفي
                      يوم من الأيام اكتشفت معالجة أجنبية تعالج بعلم كرانيوساكرال
                      ثيرابي وجربت هذا النوع من العلاج واستقدت كثير من الجلسات ،
                      رجعت اضحك رجعت أتكلم ورجعت أشوف الحياة بنظرة ثانية رجعت
                      أشوف البيت بنظره ثانية، واستغربت بأني تغيرت بهالسرعة وكيف
                      أصبحت عندي نقلة قوية في فترة قصيرة نسيبا مقارنة بالآلام
                      والتعب الذي لدي فقلت للمعالجة أريد أن اتعلم الشيء طريقة
                      الجلسات اللي قدمتيها لي ودخلت معاها في دورة مدتها سنتين
                      درست فيهم بالرغم اني كنت اشتغل ودوامي طويل وبناتي صغار
                      والدراسة كانت طويلة وفيها متطلبات تدريبية صعبة نسبياً
                      وواصلت الى ان تخرجت واخذت الدبلوما وقدمت مئات الجلسات بهذا
                      العلم وبدأت اضيف عليه الكثير من العلوم مثل علوم الوعي
                      والطاقة وعلوم النورانيات الى أن صارت جلساتي لها بصمة خاصة
                      فيني وسميتها جلسات التشافي بنبض الحياة أساسها هو أن أي شيء
                      في الكون راقبناه من غير ان نحكم أو نحاول أن نتحكم في الشيء
                      أو نحاول أن نغيره فسيتغير من نفسه ويرجع إلى فطرته ويرجع
                      إلى أصله ويبدأ ينبض بالحياة فجسمنا نفس الشيء اذا راقبناه
                      من غير أحكام من غير أي تدخل من غير أي تحكم من غير ان اننا
                      نريد أن نغير شي سيتشافى وينبض بالحياة.
                      <button
                        className="f-size-17 font-light yellow-clr pr-2"
                        onClick={toggleShortDescription}
                      >
                        ...اقرأ أكثر
                      </button>
                    </p>
                  </>
                ) : (
                  ""
                )}
                {description === true ? (
                  <div>
                    <p className="pb-5">
                      ماهو الشيء الذي جعلني اتجه من تخصص الهندسة المعمارية اللي
                      درسته وعملت فيه لفترة طويلة وكنت أحبه إلى التشافي؟ كنت احس
                      ان في شي أدور عليه خصوصا لما دخلت في مرحلة تعب نفسي وآلام
                      حادة في الظهر والرجلين .كانت الحياة كأنها واقفة وكل شي
                      واقف كل شي متعب وثقيل وكثير أيام كنت ما اقدر أكمل دوامي
                      ولازم ارجع البيت من كثر الألم .كل زاوية اطالعها ما كانت
                      حلوة مع اني انا شخصية جدا احب المرح والضحك والحركة بدأت
                      ادور على شيء بديل لأن أخذت جلسات علاج طبيعي كثير وجلسات
                      مساج وما كان ينفع مع آلام الظهر اللي عندي . ماهو الشيء
                      الذي ممكن أن يظهر لي وممكن أن يغير كل هذا جلست أبحث وفي
                      يوم من الأيام اكتشفت معالجة أجنبية تعالج بعلم كرانيوساكرال
                      ثيرابي وجربت هذا النوع من العلاج واستقدت كثير من الجلسات ،
                      رجعت اضحك رجعت أتكلم ورجعت أشوف الحياة بنظرة ثانية رجعت
                      أشوف البيت بنظره ثانية، واستغربت بأني تغيرت بهالسرعة وكيف
                      أصبحت عندي نقلة قوية في فترة قصيرة نسيبا مقارنة بالآلام
                      والتعب الذي لدي فقلت للمعالجة أريد أن اتعلم الشيء طريقة
                      الجلسات اللي قدمتيها لي ودخلت معاها في دورة مدتها سنتين
                      درست فيهم بالرغم اني كنت اشتغل ودوامي طويل وبناتي صغار
                      والدراسة كانت طويلة وفيها متطلبات تدريبية صعبة نسبياً
                      وواصلت الى ان تخرجت واخذت الدبلوما وقدمت مئات الجلسات بهذا
                      العلم وبدأت اضيف عليه الكثير من العلوم مثل علوم الوعي
                      والطاقة وعلوم النورانيات الى أن صارت جلساتي لها بصمة خاصة
                      فيني وسميتها جلسات التشافي بنبض الحياة أساسها هو أن أي شيء
                      في الكون راقبناه من غير ان نحكم أو نحاول أن نتحكم في الشيء
                      أو نحاول أن نغيره فسيتغير من نفسه ويرجع إلى فطرته ويرجع
                      إلى أصله ويبدأ ينبض بالحياة فجسمنا نفس الشيء اذا راقبناه
                      من غير أحكام من غير أي تدخل من غير أي تحكم من غير ان اننا
                      نريد أن نغير شي سيتشافى وينبض بالحياة.
                    </p>{" "}
                    <p>
                      بعد اكثر من ١٠ سنوات وانا اساعد مئات ومئات الناس في عمل
                      نقلات في حالتهم النفسية والصحية ، مرضت وحصلت لي مشكلة صحية
                      وبالرغم من كل اللي منت اعرفه ما كنت اقدر اساعد نفسي ولا
                      اسوي أي شي يغير وضعي الصحي <br />
                      <br />
                      فأدركت مع التعب والألم ان كل الدورات والشهادات اللي درستها
                      مو وقتها الحين .. والله هداني اني ارجع الى القرآن وألاقي
                      النور اللي ينوّر لي طريق الصحة والعافية من جديد .. وجدت في
                      القرآن كل اللي كنت أدوّر عليه من سنين من دروس وحكم وبيانات
                      ما كنت منتبهة لها في حياتي.. بدأت انظر لحياتي مثل ما الله
                      علّمنا في القرآن .. وهنا رجعت تدريجيا للصحة والعافية وبحثت
                      اكثر واكثر في القرآن عن الإمكانيات والقدرات والعلم
                      والهِداية والتسخير . . وبعد ما طبقت الكثير من الآيات
                      القرآنية بدأت اجهز دورات اتكلم فيها عن نور القرآن . وكيف
                      كل شخص ممكن يغير حياته لما يتصل بالقرآن ولما يسمح لنور
                      القرآن ينوّر قلبه وطريقه
                      <button
                        className="f-size-17 font-light yellow-clr"
                        onClick={toggleShortDescription}
                      >
                        ...أقرأ أقل
                      </button>
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="">
                <Link to="/contact-us">
                  <button className="theme-btn 2xl:mt-12 md:mt-8 mt-4 z-10 relative">
                  تواصل معنا
                  </button>
                </Link>
              </div>
            </div>
            <div className="mansat-videolame">
              <img src={mansatGirl}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hayyat;
