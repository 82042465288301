export const ENV = {
  appBaseUrl: process.env.REACT_APP_BASE_URL,
  serverUrl: process.env.REACT_APP_SERVER_URL,
  Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
  x_access_token: JSON.parse(localStorage.getItem('token')),
  x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,
  adminPrivateKey: process.env.REACT_APP_ADMIN_PRIVATE_KEY,
  saveItem: function (name, value) {
    localStorage.setItem(`${name}`, JSON.stringify(value));
  },
  removeItem: function (name) {
    localStorage.removeItem(name);
  },
  encryptUserData: function (data, token, id) {
    if (data) {
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('userId', JSON.stringify(id));
    }
    if (token) {
      localStorage.setItem('token', JSON.stringify(token));
    }
    return true;
  },
  getUserKeys: function (keys = null) {
    let userData = localStorage.getItem('user');
    return userData;
  },
  getToken: function () {
    let userData = localStorage.getItem('token');
    if (userData) {
      return userData;
    }
    return {};
  },
  getHeaders: function () {
    let token = JSON.parse(localStorage.getItem('token'));
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
      headers['access-token'] = token;
    }
    return headers;
  },
  logout: function () {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('timezone');
    localStorage.removeItem('items');
  },
  objectToQueryString: function (body) {
    const qs = Object.keys(body).map(key => `${key}=${body[key]}`).join('&');
    return qs;
  }
};
