import React from 'react'

const FilterComponent = ({ filterText, onFilter, onClear }) => (
   <>
      <input
         id="search"
         type="text"
         className='w-25 form-control max-width-input'
         placeholder="البحث هنا..."
         aria-label="Search Input"
         value={filterText}
         onChange={onFilter}
      />
      {/* <button type="button" className='btn btn-primary' onClick={onClear}>Clear</button> */}
   </>
);

export default FilterComponent