import { React, useEffect, useState } from "react";
import Select from "react-select";
import "./ContactUs.scss";
import "../../../../src/assets/css/cmsbanner.css";
import { toast } from "react-toastify";
import envelop from "../../../assets/images/icon-img.png";
import mobile from "../../../assets/images/mobile-img.png";
import Youtube from "../../../assets/images/youtube.svg";
import Facebook from "../../../assets/images/facebook.svg";
import Insta from "../../../assets/images/instagram.svg";
import Twiter from "../../../assets/images/twiter.svg";
import { adminApiHelper } from "../../../redux/adminApiHelper";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import Loader from "../../../config/Loader";
import { apiHelper } from "../../../redux/apiHelper";


function ContactUs() {
    const [country, setCountry] = useState();
    const [countryName, setCountryName] = useState();
    const [setting, setSetting] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        async function FetchCountryData() {
            await apiHelper("get", `/countries`)
                 .then((data) => {
                     setCountry(data?.data?.countries);
                     setIsLoading(false)
                 });
             }
             FetchCountryData();
        async function FetchData() {
            await adminApiHelper("get", `/settings`)
                .then((result) => {
                    setSetting(result?.data);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log("error", err);
                });
        }
        FetchData();
        window.scroll(0, 0);
    }, []);

    const schema = yup.object().shape({
        name: yup.string().required("مطلوب اسم"),
        email: yup
            .string()
            .required("البريد الالكتروني مطلوب")
            .matches(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                "تنسيق بريد إلكتروني غير صالح"
            ),
        phoneNumber: yup
            .string()
            .required("رقم الهاتف مطلوب")
            .matches(/^[0-9]+$/, "يجب أن يحتوي رقم الهاتف على أرقام فقط")
            .min(11, "يجب ألا يقل رقم الهاتف عن 11 رقمًا")
            .max(16, "يمكن أن يتكون رقم الهاتف من 16 رقمًا على الأكثر")
            .nullable(true),
        countryName: yup.string().required("الدولة مطلوبة"),
        message: yup.string().required("الرسالة مطلوبة"),
        subject: yup.string().required("الموضوع مطلوب"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        trigger,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const handleSelectChangeAndRegister = (name, selectedOption, options) => {
        setCountryName(selectedOption ? selectedOption.value : "");
        if (name) {
            options.value = selectedOption ? selectedOption.value : "";
        }
        if (options && options.onChange) {
            options.onChange(selectedOption);
        }
        if (selectedOption === null) {
            options.value = "";
        }
        trigger("countryName");
        return register(name, options);
    };

    const handleFormSubmit = async (data) => {
        const response = await adminApiHelper("post", "/contact", data);
        if (response?.data?.success) {
            toast.success(response?.data?.message);
            reset();
            setCountryName('')
        } else {
            toast.error(response?.data?.message);
        }
    };
    const customStyles = {
        option: (provided) => ({
          ...provided,
          fontSize: '26px', // Adjust the font size as needed
        }),
      };

    return (
        <>
            <div className="cms-pages-baner contact-baner">
                <div className="theme__container">
                    <div className="cms-content-heading">
                        <h2 className="f-size-180 lg:text-9xl md:text-5xl text-3xl  text-white bold  xl:mb-5 mb-3">
                        تواصل معنا
                        </h2>
                        <p className="2xl:text-6xl xl:text-4xl md:text-3xl text-3xl font-light text-white">
                        هل لديك استفسار؟ يمكنك التواصل مع م.نبيلة مباشرة الآن!
                        </p>
                    </div>
                </div>
            </div>
            {isLoading ? (
					<Loader />
				) : 
            <section
                id="contact"
                className="contactus--form xl:py-20 md:py-16 sm:py-12 py-6 cart--items-sec "
            >
                <div className="theme__container">
                    <div className="sm:grid cmd:grid-cols-2 common-card-style form--wraper">
                        <div className="contact-right-form">
                            <div className="flex">
                                <h2 className="dark_color 2xl:text-5xl xl:text-3xl lg:text-xl md:text-lg text-base  font-bold">
                                    هل لديك أستفسار
                                </h2>
                            </div>
                            <form
                                id="contact-form"
                                className="form-horizontal"
                                role="form"
                                onSubmit={handleSubmit(handleFormSubmit)}
                            >
                                <div className="mb-3 sm:grid cmd:grid-cols-2 gap-5">
                                    <div className="mb-3">
                                        <label className="d-block lable-style">
                                            الأسم<sup className="staric">*</sup>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            id="name"
                                            placeholder="الاسم"
                                            {...register("name")}
                                        />
                                        {errors.name && (
                                            <span className="color-red">{errors.name.message}</span>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label className="d-block lable-style">
                                        البريد الإلكتروني<sup className="staric">*</sup>
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            autoComplete="off"
                                            id="email"
                                            placeholder="البريد الإلكتروني"
                                            {...register("email")}
                                        />
                                        {errors.email && (
                                            <span className="color-red">{errors.email.message}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3 sm:grid cmd:grid-cols-2 gap-5">
                                    <div className="flex flex-col">
                                        <label className="d-block lable-style">
                                            رقم الهاتف<sup className="staric">*</sup>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            id="phoneNumber"
                                            placeholder="رقم الهاتف"
                                            {...register("phoneNumber")}
                                        />
                                        {errors.phoneNumber && (
                                            <span className="color-red">
                                                {errors.phoneNumber.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className="flex flex-col">
                                        <label className="d-block lable-style">
                                            الدولة<sup className="staric">*</sup>
                                        </label>
                                        <Select
                                            onChange={(selectedOption) =>
                                                handleSelectChangeAndRegister(
                                                    "countryName",
                                                    selectedOption,
                                                    {
                                                        defaultValue: "",
                                                        name: "countryName",
                                                    }
                                                )
                                            }
                                            options={
                                                country &&
                                                country.map((countryCode) => ({
                                                    value: countryCode?.name,
                                                    label: countryCode?.name,
                                                }))
                                            }
                                            classNamePrefix="common-select-style"
                                            styles={customStyles}
                                            value={countryName ? { value: countryName, label: countryName } : null}
                                        />
                                        {errors.countryName && (
                                            <span className="color-red">
                                                {errors.countryName.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="d-block lable-style">
                                    الموضوع<sup className="staric">*</sup>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        autoComplete="off"
                                        id="subject"
                                        placeholder="موضوع"
                                        {...register("subject")}
                                    />
                                    {errors.subject && (
                                        <span className="color-red">{errors.subject.message}</span>
                                    )}
                                </div>
                                <div className="flex flex-col">
                                    <label className="d-block lable-style">
                                    رسالتك<sup className="staric">*</sup>
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="5"
                                        placeholder=""
                                        {...register("message")}
                                    ></textarea>
                                    {errors.message && (
                                        <span className="color-red">{errors.message.message}</span>
                                    )}
                                </div>
                                <div className="md:mt-8 mt-5 text-center">
                                    <button type="submit" className="theme-btn">
                                        ارسل لنا
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="contact--info">
                            <h2 className="dark_color 2xl:text-7xl xl:text-3xl lg:text-xl md:text-3xl text-3xl font-bold">
                            تواصل معنا
                            </h2>
                            <ul className="contact-sources">
                                <li>
                                    <span className="img-container">
                                        <img src={envelop} alt="no image found" />
                                    </span>
                                    <span className="orange-color source-name 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-3xl ">
                                        البريد الالكتروني
                                    </span>
                                    <a className="source-lato" href={`mailto:${setting?.email}`}>
                                        {setting?.email}
                                    </a>
                                </li>
                                <li>
                                    <span className="img-container">
                                        <img src={mobile} alt="no image found" />
                                    </span>
                                    <span className="orange-color source-name 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-lg ">رقم التواصل (واتس اب فقط)</span>
                                    <a className="source-lato" href={`tel:${setting?.phone}`}>
                                        {setting?.phone}
                                    </a>
                                </li>
                            </ul>
                            <div className="map-setting">
                                <iframe
                                    className="map"
                                    src={setting?.googleMapLink}
                                    width="100%"
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                            <div className="social-icons">
                                <h3 className="2xl:text-4xl xl:text-2xl lg:text-xl md:text-3xl text-2xl font-bold text-center">
                                حسابات وسائل التواصل الاجتماعي
                                </h3>
                                <ul className="social-links">
                                    <Link to={setting?.telegram}>
                                        {" "}
                                        <li className="text-white text-base pl-5">
                                            <img src={Twiter} alt="Telegram" />
                                        </li>
                                    </Link>
                                    <Link to={setting?.instagram}>
                                        {" "}
                                        <li className="text-white text-base pl-5">
                                            <img src={Insta} alt="Instagram" />
                                        </li>
                                    </Link>
                                    <Link to={setting?.facebook}>
                                        {" "}
                                        <li className="text-white text-base pl-5">
                                            <img src={Facebook} alt="Facebook" />
                                        </li>
                                    </Link>
                                    <Link to={setting?.youtube}>
                                        {" "}
                                        <li className="text-white text-base pl-5">
                                            <img src={Youtube} alt="Youtube" />
                                        </li>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }
        </>
    );
}

export default ContactUs;
