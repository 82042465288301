import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { adminApiHelper } from '../../../redux/adminApiHelper';

function Paymentbaner() {
  const navigate = useNavigate();
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const tap_id = params.get("tap_id");
  const userId = JSON.parse(localStorage.getItem('userId'));

  const fetchPayment = async()=>{
    let resData = await adminApiHelper('post', `/payments/`, {tap_id});
      if (resData?.data?.success) {
        let id = [];
        resData?.data?.order?.items.forEach((item) => {
          id.push(item?._id);
        })
          const { data } = resData;
          if (data?.payment?.type === 'consultation') {
              navigate('/user-consultations')
              toast.success(data?.message)
              return;
          }
          const courseData = {
              userId: data?.payment?.userId,
              courseIds: id,
          }
          if (data?.payment?.type === 'course') {
              let responseData = await adminApiHelper('post', `/user/buy-course`, courseData);
              if (responseData?.data) {
                  const { data } = responseData;
                  toast.success(data?.message)
                  localStorage.removeItem('items');
                  navigate('/user-courses')
              }
          }
          else if (data?.payment?.type === 'event') {
              try {
                  let resEvent = await adminApiHelper('post', `/events/join/${id}?userId=${userId}`, '');
                  if (resEvent?.data) {
                      const { data } = resEvent;
                      toast.success(data?.message)
                      navigate('/user-events')
                  }
              } catch (error) {
                  toast.error(error.resEvent.message)
              }
          }
          else if (data?.payment?.type === 'subscription') {
            toast.success("Subscription created successfully")
            navigate('/plans')
          }
      }else{
        navigate("/")
      }

    
  }
  useEffect(() => {
    fetchPayment()
    window.scrollTo(0, 1)
  }, [])

  return (
    <div>
      <div className='cms-wraper'>
        <div className="cms-pages-baner chechout_banner page_not_found">
          <div className="theme__container">
            <div className=''>
              <h2 className='2xl:text-8xl xl:text-7xl lg:text-5xl text-3xl  dark_color bold mb-5 text-center'>
                Please Wait
              </h2>
              <p className='2xl:text-3xl xl:text-2xl md:text-lg text-base font-light dark_color text-center '>
              Your payment request is being processed
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Paymentbaner
