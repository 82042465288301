import React, { useEffect } from 'react'
import './Course.scss'
import Faqat from "../../common/Faqat/Faqat";
import Shahadat from "../../common/Shahadat/Shahdat";
import mansatGirl from "../../../assets/images/mansat_girl.png";
import CoursesTabs from './CoursesTabs';
import { Link } from 'react-router-dom';

function Course() {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <>
            <div className='product-banner'>
                <section className="site__banner " id="home">
                    <div className="theme__container w-full">
                        <div className='relative z-10'>
                            <div className="product-shifa-content  leading-none">
                                <h1 className='product-site-heading'>الدورات </h1>
                                <h6 className='text-6xl font-light text-white'>اتخذ القرار الصحيح لحياة أفضل!</h6>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className='hayyat_content '>
                <div className='theme__container'>
                    <div className='hayyat-wrapper flex items-center justify-between pt-28 md:pt-20 '>
                        <div className='content-text'>
                            <div>
                                <h2 className='2xl:text-4xl mb-8 md:text-3xl dark_color font-bold'>مرحبا بكم في مكتبة الدورات الخاصة بي<span className='orange-color f-size-52 whitespace-nowrap'>  (م. نبيلة)</span></h2>
                            </div>
                            <div className='hayyat-text font-size-27 font-light dark_color'>
                                <p className='pb-5'>تقدم م. نبيلة مجموعة من الدورات التي تأخذ بيدك لحياة أفضل وهي دورات مفيدة للأشخاص الذين يسعون إلى تطوير تجربتهم في الحياة والتواصل مع ذواتهم بصورة أفضل</p>
                                <p>تتنوع الدورات التدريبية التي تقدمها م. نبيلة في الأهداف بحيث تخدم فئة كبيرة من الأشخاص المهتمين بالتشافي والوعي <br /><br /> تتميز م. نبيلة بإسلوبها الفريد في تقديم الدورات والذي يشتمل على تقديم مادة علمية، وجلسات تشافي ولا تخلو الدورات من التأملات العميقة ذات التأثير القوي والمفيد.</p>
                            </div>
                            <div className="text-start">
                                <Link to="/contact-us"> <button className='theme-btn 2xl:mt-12 md:mt-8 mt-4 mt-12'>تواصل معنا</button></Link>
                            </div>
                        </div>
                        <div className="mansat-videolame">
                            <img src={mansatGirl}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='products-tabs-wrapper'>
                <div className='alim__nabeela_content 2xl:mt-24 md:mt-16 pb-28'>
                    <div className='theme__container'>
                        <div className='fee-alim-wrapper z-10 relative font-light text-center pt-20 '>
                            <h2 className='dark_color'>مكتبة دورات م.نبيلة</h2>
                            <span className='orange-color f-size-52 font-medium'>"منذ أن خلقك الله، وكل خلية فيك تنبض بالحياة. فهل أنت مُدرك لذلك؟”</span>
                            <div className='tabs__wraper'>
                                <CoursesTabs />
                            </div>
                        </div>
                        {/* <div className="text-center">
                            <Link to="/contact-us"> <button className='theme-btn  mt-12 m-auto courses_join_btn'>تواصل مع نبيلة</button></Link>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='faqat-wraper product-faqat-wraper'>
                <Faqat />
            </div>
            <div className='product-shahdat-wraper'>
                <Shahadat />
            </div>

        </>
    )
}

export default Course
